import React, { useState } from 'react'
import { toast } from "react-toastify";
import axios from "axios";

const EmployeeReferalForm = ({data,jobminexpRequired,jobId}) => {
    const [form, setForm] = useState({
        candidate_full_name:'',
        candidate_email:'',
        candidate_aadhar:'',
        candidate_mobile:'',
        currentCtc: "",
        expectedCtc: "",
        readyToRelocate: "",
        noticePeriod: "",
        reasonForChange: "",
        totalExperience: "",
        relevantExperience: "",
        currentCompany: "",
        currentDesignation: "",
        highestQualification: "",
        courseName: "",
        college: "",
        passoutYear:'',
        hearAboutUs: "",
        resume: null,
    });

    const [errors, setErrors] = useState({});
    const [isFresher, setIsFresher] = useState(true);
    const [file, setFile] = useState(null); // To store the selected file
    const [isUploading, setIsUploading] = useState(false); // To track if uploading
    const [uploadStatus, setUploadStatus] = useState(null); // To store upload status
    const [isUploaded, setIsUploaded] = useState(false); // To track if file is uploaded
    const [error, setError] = useState("");

    // File change handler
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        // Check if a file was selected
        if (selectedFile) {
            // Restrict file size to 5MB (5 * 1024 * 1024)
            const maxSize = 5 * 1024 * 1024; // 5MB
            if (selectedFile && selectedFile.type === 'application/pdf') {
                if (selectedFile.size > maxSize) {
                    setError("File size exceeds 5MB. Please upload a smaller file.");
                    setFile(null); // Reset file selection
                } else {
                    setFile(selectedFile);
                    setError(""); // Clear error if file is valid
                }
            } else {
                alert('Please select a valid PDF file');
            }
        }
    }

    // File upload handler
    const handleFileUpload = async () => {
        if (!file) return;

        setIsUploading(true);
        setUploadStatus('Uploading...');
        const formData = new FormData()
        formData.append('file', file)
        try {
            const config = {
                method: "post",
                url: `${process.env.REACT_APP_UPLOAD_DATA}/candidate/resume`,  // Make sure REACT_APP_UPLOAD_DATA is defined in .env
                headers: {
                    Accept: "application/json",  // Expect JSON response
                    'Content-Type': 'multipart/form-data', // Send data as JSON
                },
                data: formData // Ensure this is a valid object
            };
            // Send the request and await the response
            // const response = await axios(config);
            // console.log(response);
        } catch (error) {
            console.log(error)
            if (error.response) {
                // Check if the error has response data
                if (error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error("Something went wrong! Please try again later.");
                }
            } else {
                // If no response, it's a network or unexpected error
                toast.error("Network error! Please check your internet connection.");
            }
        }
    };

    // Delete file handler
    const handleDeleteFile = () => {
        setFile(null);
        setIsUploaded(false);
        setUploadStatus(null);
    };

    const handleInputChange = (e) => {
        const { name, value, type, files } = e.target;
        setForm((prev) => ({
            ...prev,
            [name]: type === "file" ? files[0] : value,
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        if(!form.candidate_full_name) newErrors.candidate_full_name='Candidate Full Name is Required';
        if(!form.candidate_email) newErrors.candidate_email='Candidate Valid E-Mail is Required';
        if(!form.candidate_aadhar || (form.candidate_aadhar.toString().length >12 &&form.candidate_aadhar.toString().length <12)){
            newErrors.candidate_aadhar ='Enter a valid 12 digit aadhar number'
        }
        if(!form.candidate_mobile || (form.candidate_mobile.toString().length >10 &&form.candidate_mobile.toString().length <10)){
            newErrors.candidate_mobile = 'Enter a valid 10 digit mobile number'
        }
        if (!form.currentCtc) newErrors.currentCtc = "Current CTC is required.";
        if (!form.expectedCtc) newErrors.expectedCtc = "Expected CTC is required.";
        if (!form.readyToRelocate) newErrors.readyToRelocate = "Please select an option.";
        if (!form.noticePeriod) newErrors.noticePeriod = "Notice period is required.";
        if (!form.reasonForChange) newErrors.reasonForChange = "Reason for change is required.";
        if (!form.highestQualification) newErrors.highestQualification = "Highest qualification is required.";
        if (!form.college) newErrors.college = "College/University is required.";
        if (!form.hearAboutUs) newErrors.hearAboutUs = "Please select how you heard about us.";
        if (!form.resume) newErrors.resume = "Please upload your resume.";
        if (!isFresher && (!form.currentCompany || !form.currentDesignation || !form.relevantExperience)) {
            newErrors.currentCompany = "Current company is required.";
            newErrors.currentDesignation = "Current designation is required.";
            newErrors.relevantExperience = 'Relevant Experience is required';
        }
        if(!form.passoutYear) newErrors.passoutYear='Passout Year is Required'
        if(!form.courseName) newErrors.courseName='Course Name is required'
        if (jobminexpRequired.toString() !== form.relevantExperience) {
            toast.error(`You are not eligible for this post. Company required Minimum Experience of ${jobminexpRequired} year`)
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            // Create a new FormData instance
        const formData = new FormData();

        // Append form values to FormData
        Object.keys(form).forEach((key) => {
            if (form[key] !== null) {
                formData.append(key, form[key]);
            }
        });
        formData.append('job_id',jobId)
        formData.append('emp_id',data.emp_id)
        formData.append('emp_name',data.fullName)
        formData.append('emp_email',data.email)

        console.log(formData)
        }
    };

    const labelStyle = { color: "orange", fontWeight: "bold" };
    const errorStyle = { color: "red", fontSize: "small" };

    return (
        <div className="container mt-5">
            <h2 className="text-center" style={{ color: "orange" }}>
                Job Application Form
            </h2>
            <form onSubmit={handleSubmit} className="p-4 border rounded shadow">
                {/* Uneditable Fields */}
                <div className="mb-3">
                    <label style={labelStyle}>Employee Name:</label>
                    <input type="text" className="form-control" value={data?.fullName} readOnly style={{ userSelect: 'none' }} />
                </div>
                <div className="mb-3">
                    <label style={labelStyle}>Email ID:</label>
                    <input type="email" className="form-control" value={data?.email} readOnly style={{ userSelect: 'none' }} />
                </div>
                <div className="mb-3">
                    <label style={labelStyle}>Employee ID:</label>
                    <input type="text" className="form-control" value={data?.emp_id} readOnly style={{ userSelect: 'none' }} />
                </div>

                {/* Editable Fields */}
                <div className="mb-3">
                    <label style={labelStyle}>Candidate FullName:</label>
                    <input
                        type="text"
                        name="candidate_full_name"
                        className="form-control"
                        value={form.candidate_full_name}
                        onChange={handleInputChange}
                    />
                    {errors.candidate_full_name && <div style={errorStyle}>{errors.candidate_full_name}</div>}
                </div>
                <div className="mb-3">
                    <label style={labelStyle}>Candidate Email:</label>
                    <input
                        type="email"
                        name="candidate_email"
                        className="form-control"
                        value={form.candidate_email}
                        onChange={handleInputChange}
                    />
                    {errors.candidate_email && <div style={errorStyle}>{errors.candidate_email}</div>}
                </div>
                <div className="mb-3">
                    <label style={labelStyle}>Candidate Aadhar Number:</label>
                    <input
                        type="number"
                        maxLength={12}
                        name="candidate_aadhar"
                        className="form-control"
                        value={form.candidate_aadhar}
                        onChange={handleInputChange}
                    />
                    {errors.candidate_aadhar && <div style={errorStyle}>{errors.candidate_aadhar}</div>}
                </div>
                <div className="mb-3">
                    <label style={labelStyle}>Candidate Contact Number:</label>
                    <input
                        type="number"
                        maxLength={10}
                        name="candidate_mobile"
                        className="form-control"
                        value={form.candidate_mobile}
                        onChange={handleInputChange}
                    />
                    {errors.candidate_mobile && <div style={errorStyle}>{errors.candidate_mobile}</div>}
                </div>
                <div className="d-flex flex-wrap align-items-center gap-3 mb-3 mt-3">
                    {/* Label for file input */}
                    <label style={labelStyle} htmlFor="file-input">
                        Upload Resume (pdf only):
                    </label>
                    {error && <p style={{ color: "red" }}>{error}</p>}
                    {/* File input (hidden) */}
                    <input
                        type="file"
                        name="resume"
                        className="form-control"
                        accept=".pdf"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                        id="file-input"
                    />

                    {/* Label to trigger file input */}
                    {!isUploaded && (
                        <label htmlFor="file-input" className="btn btn-primary">
                            Select File
                        </label>
                    )}

                    {/* Uneditable input field to show file name */}
                    {file && !isUploaded && (
                        <input
                            type="text"
                            value={file.name}
                            className="form-control"
                            readOnly
                            style={{ width: '250px' }}
                        />
                    )}

                    {/* Upload button or spinner */}
                    {file && !isUploaded && (
                        <button
                            className="btn btn-success"
                            onClick={handleFileUpload}
                            disabled={isUploading || !file}
                        >
                            {isUploading ? (
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            ) : (
                                'Upload'
                            )}
                        </button>
                    )}

                    {/* Delete button after upload */}
                    {isUploaded && (
                        <button className="btn btn-danger" onClick={handleDeleteFile}>
                            Delete
                        </button>
                    )}

                    {/* Upload status */}
                    {uploadStatus && <div className="text-info mt-2">{uploadStatus}</div>}
                </div>
                <div className="mb-3">
                    <label style={labelStyle}>Current CTC:</label>
                    <input
                        type="number"
                        name="currentCtc"
                        className="form-control"
                        value={form.currentCtc}
                        onChange={handleInputChange}
                    />
                    {errors.currentCtc && <div style={errorStyle}>{errors.currentCtc}</div>}
                </div>
                <div className="mb-3">
                    <label style={labelStyle}>Expected CTC:</label>
                    <input
                        type="number"
                        name="expectedCtc"
                        className="form-control"
                        value={form.expectedCtc}
                        onChange={handleInputChange}
                    />
                    {errors.expectedCtc && <div style={errorStyle}>{errors.expectedCtc}</div>}
                </div>
                <div className="mb-3">
                    <label style={labelStyle}>Ready to Relocate:</label>
                    <div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="readyToRelocate"
                                value="yes"
                                checked={form.readyToRelocate === "yes"}
                                onChange={handleInputChange}
                            />
                            <label className="form-check-label">Yes</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="readyToRelocate"
                                value="no"
                                checked={form.readyToRelocate === "no"}
                                onChange={handleInputChange}
                            />
                            <label className="form-check-label">No</label>
                        </div>
                    </div>
                    {errors.readyToRelocate && <div style={errorStyle}>{errors.readyToRelocate}</div>}
                </div>

                <div className="mb-3">
                    <label style={labelStyle}>Notice Period:</label>
                    <select
                        name="noticePeriod"
                        className="form-select"
                        value={form.noticePeriod}
                        onChange={handleInputChange}
                    >
                        <option value="">Select</option>
                        <option value="Immediate">Immediate</option>
                        <option value="15days">15 days</option>
                        <option value="30days">30 days</option>
                        <option value="45days">45 days</option>
                        <option value="60days">60 days</option>
                    </select>
                    {errors.noticePeriod && <div style={errorStyle}>{errors.noticePeriod}</div>}
                </div>


                <div className="mb-3">
                    <label style={labelStyle}>Reason for Change:</label>
                    <input
                        type="text"
                        name="reasonForChange"
                        className="form-control"
                        value={form.reasonForChange}
                        onChange={handleInputChange}
                    />
                    {errors.reasonForChange && <div style={errorStyle}>{errors.reasonForChange}</div>}
                </div>

                <div className="mb-3">
                    <label style={labelStyle}>Total Experience:</label>
                    <select
                        name="totalExperience"
                        className="form-select"
                        value={form.totalExperience}
                        onChange={(e) => {
                            handleInputChange(e);
                            setIsFresher(e.target.value === "fresher");
                        }}
                    >
                        <option value="">Select</option>
                        <option value="fresher">Fresher</option>
                        <option value="experienced">Experienced</option>
                    </select>
                    {errors.totalExperience && <div style={errorStyle}>{errors.totalExperience}</div>}
                </div>

                {form.totalExperience && form.totalExperience !== "" && (
                    <>
                        {(!isFresher) && (
                            <>
                                <div className="mb-3">
                                    <label style={labelStyle}>Relevant Experience:</label>
                                    <input
                                        type="number"
                                        name="relevantExperience"
                                        className="form-control"
                                        value={form.relevantExperience}
                                        onChange={handleInputChange}
                                    />
                                    {errors.relevantExperience && <div style={errorStyle}>{errors.relevantExperience}</div>}
                                </div>
                                <div className="mb-3">
                                    <label style={labelStyle}>Current Company:</label>
                                    <input
                                        type="text"
                                        name="currentCompany"
                                        className="form-control"
                                        value={form.currentCompany}
                                        onChange={handleInputChange}
                                    />
                                    {errors.currentCompany && <div style={errorStyle}>{errors.currentCompany}</div>}
                                </div>
                                <div className="mb-3">
                                    <label style={labelStyle}>Current Designation:</label>
                                    <input
                                        type="text"
                                        name="currentDesignation"
                                        className="form-control"
                                        value={form.currentDesignation}
                                        onChange={handleInputChange}
                                    />
                                    {errors.currentDesignation && <div style={errorStyle}>{errors.currentDesignation}</div>}
                                </div>
                            </>
                        )}
                    </>
                )}

                <div className="mb-3">
                    <label style={labelStyle}>Highest Qualification:</label>
                    <select
                        name="highestQualification"
                        className="form-select"
                        value={form.highestQualification}
                        onChange={handleInputChange}
                    >
                        <option value="">Select</option>
                        <option value="Diploma">Diploma</option>
                        <option value="Bachelor's Degree">Bachelor's Degree</option>
                        <option value="Master's Degree">Master's Degree</option>
                        <option value="PhD">Ph.D</option>
                    </select>
                    {errors.highestQualification && <div style={errorStyle}>{errors.highestQualification}</div>}
                </div>

                <div className="mb-3">
                    <label style={labelStyle}>Course Name:</label>
                    <select
                        name="courseName"
                        className="form-select"
                        value={form.courseName}
                        onChange={handleInputChange}
                        disabled={
                            form.highestQualification === "High School" ||
                            form.highestQualification === "Intermediate"
                        }
                    >
                        <option value="">Select</option>
                        {form.highestQualification === "Diploma" && (
                            <>
                                <option value="Diploma in Computer Science">Diploma in Computer Science</option>
                                <option value="Diploma in Networking">Diploma in Networking</option>
                                <option value="Diploma in Web Development">Diploma in Web Development</option>
                                <option value="Diploma in Data Science">Diploma in Data Science</option>
                                <option value="Diploma in Cyber Security">Diploma in Cyber Security</option>
                                <option value="Others">Others</option>
                            </>
                        )}
                        {form.highestQualification === "Bachelor's Degree" && (
                            <>
                                <option value="B.Tech">B.Tech</option>
                                <option value="B.Sc in Computer Science">B.Sc in Computer Science</option>
                                <option value="B.Com in IT">B.Com in IT</option>
                                <option value="B.A in Data Analysis">B.A in Data Analysis</option>
                                <option value="Others">Others</option>
                            </>
                        )}
                        {form.highestQualification === "Master's Degree" && (
                            <>
                                <option value="M.Tech">M.Tech</option>
                                <option value="M.Sc in AI">M.Sc in AI</option>
                                <option value="M.Com in IT Management">M.Com in IT Management</option>
                                <option value="MBA in IT">MBA in IT</option>
                                <option value="MCA">MCA</option>
                                <option value="Others">Others</option>
                            </>
                        )}
                        {form.highestQualification === "PhD" && (
                            <>
                                <option value="Ph.D in Computer Science">Ph.D in Computer Science</option>
                                <option value="Ph.D in AI and ML">Ph.D in AI and ML</option>
                                <option value="Ph.D in Cyber Security">Ph.D in Cyber Security</option>
                                <option value="Ph.D in Data Science">Ph.D in Data Science</option>
                                <option value="Ph.D in Networking">Ph.D in Networking</option>
                                <option value="Others">Others</option>
                            </>
                        )}
                    </select>
                    {(form.highestQualification === "High School" ||
                        form.highestQualification === "Intermediate") && (
                            <div style={errorStyle}>Higher qualification is required to apply.</div>
                        )}
                    {errors.courseName && <div style={errorStyle}>{errors.courseName}</div>}
                </div>

                {/* Input Field for Custom Course Name */}
                {form.courseName === "Others" && (
                    <div className="mb-3">
                        <label style={labelStyle}>Specify Course Name:</label>
                        <input
                            type="text"
                            name="customCourseName"
                            className="form-control"
                            value={form.customCourseName}
                            onChange={(event) => {
                                const { value } = event.target;
                                setForm({ ...form, courseName: value, customCourseName: value });
                            }}
                        />
                        {errors.customCourseName && (
                            <div style={errorStyle}>{errors.customCourseName}</div>
                        )}
                    </div>
                )}



                <div className="mb-3">
                    <label style={labelStyle}>College/University:</label>
                    <input
                        type="text"
                        name="college"
                        className="form-control"
                        value={form.college}
                        onChange={handleInputChange}
                    />
                    {errors.college && <div style={errorStyle}>{errors.college}</div>}
                </div>

                <div className="mb-3">
                    <label style={labelStyle}>Passout Year:</label>
                    <select
                        name="passoutYear"
                        className="form-select"
                        value={form.passoutYear}
                        onChange={handleInputChange}
                    >
                        <option value="">Select</option>
                        {Array.from({ length: 21 }, (_, i) => new Date().getFullYear() - 10 + i).map(year => (
                            <option key={year} value={year}>{year}</option>
                        ))}
                    </select>
                    {errors.passoutYear && <div style={errorStyle}>{errors.passoutYear}</div>}
                </div>


                <div className="mb-3">
                    <label style={labelStyle}>How did you hear about us?</label>
                    <select
                        name="hearAboutUs"
                        className="form-select"
                        value={form.hearAboutUs}
                        onChange={handleInputChange}
                    >
                        <option value="">Select</option>
                        <option value="LinkedIn">LinkedIn</option>
                        <option value="Job Portal">Job Portal</option>
                        <option value="Employee Referral">Employee Referral</option>
                        <option value="Other">Other</option>
                    </select>
                    {errors.hearAboutUs && <div style={errorStyle}>{errors.hearAboutUs}</div>}
                </div>
                <div className="text-center">
                    <button type="submit" className="btn btn-primary" style={{ backgroundColor: "orange", border: "none" }}>
                        Submit
                    </button>
                </div>

                <div className="text-center">
                    <button type="submit" className="btn btn-primary" style={{ backgroundColor: "orange", border: "none" }}>
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
}

export default EmployeeReferalForm