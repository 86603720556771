import React,{useEffect, useState} from 'react'
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import getNotification from '../Redux/Action/action'
import axios from 'axios'
const { REACT_APP_UPLOAD_DATA } = process.env
const { REACT_APP_REDIRECT_URI } = process.env

function JobPostingApproval() {
  const [viewobject, setviewobject] = useState({});
const [comments,setcomments]=useState("")
const [resultscreen,setresultscreen]=useState(false)
const dispatch=useDispatch()
const {id}=useParams()
console.log(id)
useEffect(()=>{
  fetchjobposting()
},[])
const fetchjobposting=async()=>{
  
    try {

      const config = {
        method: "get",
        url: `${REACT_APP_UPLOAD_DATA}/job_posting_id?job_id=${id}`,
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
        },

      };
      const getResponse = await axios(config);
     // console.log(getResponse);
      if (getResponse.data !== "Invalid User!") {
        //setnewjobcreationvalue(initialnewjobcreationvalue)
        setviewobject(getResponse.data.job_posting)
      
      }
    
    } catch (err) {
      console.log("Server Error", err);
      if (err.response && err.response.status === 500) {
        dispatch(
          getNotification({
            message: "Server is Down! Please try again after sometime",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 400) {
        dispatch(
          getNotification({
            message: "Input is not in prescribed format",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 422) {
        dispatch(
          getNotification({
            message: "Input is not in prescribed format",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 404) {
        dispatch(
          getNotification({
            message: "Page not Found",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 401) {
        dispatch(
          getNotification({
            message: "Session expired! Please log in again",
            type: "default",
          })
        );
 //       setTimeout(UserService.doLogin(), 1000)
      } else {
        dispatch(
          getNotification({
            message: "Server is Down! Please try again after sometime",
            type: "default",
          })
        );
      }
    }
  
 
}
  const handleapproval=async (type)=>{
if(comments){

}
else{
  document.getElementById("comments").focus()
}
  }
  return (
    <div>
        <div className='container masterhomepage'  >
    <div className='p-3'>
      <div className='my-3'>
    <h4 className=' orangetheme'>Job Posting Approval</h4>
    <div class=" p-4">


{resultscreen ? <div>Job Posting has been approved</div>:<div className='my-2'>
  Details of the job posting below:
  <div className='row p-2'>

    {viewobject && <table className="table table-sm table-striped ">

<tbody>
  <tr>
    <th scope="row">Designation</th>
    <td>{viewobject.designation}</td>
  </tr>
  <tr>
    <th scope="row">Job Title</th>
    <td>{viewobject.job_position}</td>
  </tr>

  <tr>
    <th scope="row">Mandatory Skills</th>
    <td>{viewobject.mandatory_skillset}</td>
  </tr>
  <tr>
    <th scope="row">Good to Have Skills</th>
    <td>{viewobject.mandatory_skillset}</td>
  </tr>
  <tr>
    <th scope="row">Soft Skills</th>
    <td>{viewobject.soft_skills}</td>
  </tr>
  <tr>
    <th scope="row">Summary</th>
    <td>{viewobject.summary}</td>
  </tr>
  <tr>
    <th scope="row">Experience(in years) </th>
    <td> {viewobject.relevant_experience_min}-{viewobject.relevant_experience_max}</td>
  </tr>
  <tr>
    <th scope="row">Notice Period (in days)</th>
    <td>{viewobject.notice_period_days}</td>
  </tr>
  <tr>
    <th scope="row">Job Type</th>
    <td>{viewobject.job_type}</td>
  </tr>
  <tr>
    <th scope="row">Reason of Recruitment</th>
    <td>{viewobject.reason_for_recruitment}</td>
  </tr>
  <tr>
    <th scope="row">Preferred Working Location</th>
    <td>
      {viewobject.preferred_working_location}
    </td>
  </tr>
  <tr>
    <th scope="row">Budget Band(in lacs)</th>
    <td>{viewobject.budget_min_range}-{viewobject.budget_max_range}</td>
  </tr>
  <tr>
    <th scope="row">Customer</th>
    <td>{viewobject.customer_type}</td>
  </tr>
  <tr>
    <th scope="row">Customer Name</th>
    <td>{viewobject.customer}</td>
  </tr>
  <tr>
    <th scope="row">No. of Positions</th>
    <td>{viewobject.no_of_positions}</td>
  </tr>
  <tr>
    <th scope="row">Panelist</th>
    <td>{viewobject?.panel_list}</td>
  </tr>
</tbody>
</table>  }
    

  </div> 
  <div className=''>
  <div className='mb-2'>
      <span className='my-1'>Comments:</span>
      <textarea rows="2" className='form-control ' id="comments" value={comments} onChange={(e)=>setcomments(e.target.value)}/>
  
    </div>
 <div className='my-2 d-flex flex-row-reverse'>
 <a className='a ml-4' onClick={()=>{handleapproval("Approve")}}> Approve </a> 
 <a className='a ml-4' onClick={()=>{handleapproval("Reject")}}>Reject</a>
 </div>
    
  
  
  </div>
   </div>}



</div>
       </div></div></div></div>
  )
}

export default JobPostingApproval