import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from './useAuth';

const PrivateRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth();

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <div className="w-50">
          <div className="progress">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              style={{ width: '100%' ,backgroundColor: '#ff7f2a'}}
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              Loading...
            </div>
          </div>
        </div>
      </div>
    );
  }
  return isAuthenticated ? children : <Navigate to="/candidate/login" />;
};

export default PrivateRoute;
