import React from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router'

function FooterPages() {
  const navigate = useNavigate()
  const location = useLocation()
  return (
    <div className="container-fluid card p-4" style={{ backgroundColor: "#fafafa" }}>
      <div className="row mx-4 justify-content-between align-items-center">
        <div className="col-12 d-flex align-items-center justify-content-between mb-3">
          <div className="d-flex align-items-center">
            <img src="/Assets/Images/quationlogoBg0.png" style={{ height: "32px" }} alt="Logo" />
            <span style={{ color: "grey", fontSize: "12px", marginLeft: "10px" }}>
              Copyright©2023, Quation Solutions Private Limited. All rights reserved.
            </span>
          </div>

          <div className="d-flex justify-content-between align-items-center">
            <Link
              className="nav-link text-black d-flex align-items-center mx-2"
              href="mailto:quation@quation.in"
              style={{ fontSize: "14px", transition: "color 0.3s" }}
            >
              <img className="mr-2" width="20" height="20" src="https://img.icons8.com/fluency/20/mail--v1.png" alt="mail-icon" />
              Contact Us: quation@quation.in
            </Link>

            <div className="d-flex">
              <Link
                className="nav-link text-black mx-2"
                to="/privacy-policy"
                style={{ color: "#000000", fontSize: "14px", transition: "color 0.3s" }}
              >
                <span className="underline-nav">Privacy Policy</span>
              </Link>
              <Link
                className="nav-link text-black mx-2"
                to="/terms-of-service"
                style={{ color: "#000000", fontSize: "14px", transition: "color 0.3s" }}
              >
                <span className="underline-nav">Terms of Service</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>


  )
}

export default FooterPages