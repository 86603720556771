import React, { useEffect, useState } from 'react'
import axios from 'axios'
import quationQ from '../Images/quationQ.png'
import { Navigate, useNavigate } from 'react-router-dom';
const CandidateDashboard = () => {
  const navigate = useNavigate()
  const [fetchingResult, setFetchingResult] = useState(false);
  const [allSubmission, setAllSubmission] = useState(null);
  const access_token = localStorage.getItem('access_token');
  // Define primary color for inline styles
  const primaryColor = '#FFA500'; // Orange color
  // Fetch submissions from the API
  const getAllSubmission = async () => {
    setFetchingResult(true);
    try {
      const config = {
        method: "get",
        url: `${process.env.REACT_APP_UPLOAD_DATA}/candidate/${access_token}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        }
      };
      const response = await axios(config);
      console.log(response);
      if (response.status === 200) {
        setAllSubmission(response.data); // Assuming response contains data as array
        setFetchingResult(false);
      }
    } catch (error) {
      setFetchingResult(false);
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    getAllSubmission();
  }, []);

  // Loader Component
  const Loader = () => (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <div className="spinner-border" style={{ borderTopColor: primaryColor }} role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );

  // UI Component to display submissions
  const SubmissionList = () => (
    <div className="row g-4">
      {allSubmission && allSubmission.length > 0 ? (
        allSubmission.map((submission, index) => (
          <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
            <div className="card shadow-lg border-0">
              <div className="card-body">
                <h5 className="card-title" style={{ color: primaryColor }}>{submission.job_position}</h5>
                <p className="card-text">Status: <strong className='text-success'>{submission.applicationStatus}</strong></p>
                <p className="card-text">Job ID: {submission.job_id}</p>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="col-12">
          <div className="alert alert-info" role="alert">
            No submissions found.
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className="container my-5">
      {/* Top Right Logout Button */}
      <button
        className="btn btn-danger position-absolute top-0 end-0 m-4"
        style={{ borderRadius: '50%', padding: '10px 15px' }}
        onClick={() => {
          localStorage.removeItem("access_token");
          navigate('/');
        }}
      >
        <i className="bi bi-box-arrow-right">Logout</i>
      </button>

      {/* Dashboard Header */}
      <section className="text-center mb-5">
        <h2 className="display-4" style={{ color: primaryColor }}>Candidate Dashboard</h2>
        <p className="lead text-muted">Here you can find your application status for each job you have applied to.</p>
      </section>

      {/* Submission List */}
      <section className="mb-5">
        {fetchingResult ? <Loader /> : <SubmissionList />}
      </section>

      {/* Footer Section */}
      <section className="text-center mt-5 py-4" style={{ backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)' }}>
        <p className="text-muted" style={{ fontSize: '1.1rem', fontWeight: '500', color: '#6c757d' }}>
          Powered by
          <img
            src={quationQ}
            alt="Company Logo"
            style={{
              height: '40px',
              marginLeft: '12px',
              borderRadius: '50%',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
              transition: 'transform 0.3s ease'
            }}
            className="company-logo"
            onClick={() => {
              localStorage.removeItem("access_token");
              navigate('/');
            }}
          />
          <span style={{ marginLeft: '8px', color: '#343a40', fontWeight: '600' }}>Quation Solution Private Limited</span>
        </p>
        <style jsx>{`
          .company-logo:hover {
            transform: scale(1.1);
          }
        `}</style>
      </section>
    </div>
  );
}

export default CandidateDashboard