import React, { useEffect, useState } from 'react'
import LandingPageNavbar from './LandingPageNavbar/LandingPageNavbar'
import MainContentOfLandingPage from './MainContentOfLandingPage'
import FooterPages from '../FooterPages'
import './RecruitProLandingPageCSS.css'
const RecruitProLandingPage = () => {

  return (
    <div className="page-container">
      <LandingPageNavbar />
      <div className="main-content-wrapper">
        <MainContentOfLandingPage />
      </div>
      <FooterPages />
    </div>
  )
}

export default RecruitProLandingPage