import React, { useState } from 'react'
import './LandingPageNavbarCSS.css'
import quationQ from '../../Images/quationQ.png'
import { useNavigate } from 'react-router-dom';
const LandingPageNavbar = () => {
  const [showOptions, setShowOptions] = useState(false); // Manage the visibility of options
  const navigate = useNavigate()
  const styles = {
    button: {
      backgroundColor: "#ff6600", // Orange color for the button
      color: "white",
      border: "none",
      padding: "10px 20px",
      fontSize: "16px",
      cursor: "pointer",
      borderRadius: "4px",
      transition: "background-color 0.3s ease",
    },
    buttonHover: {
      backgroundColor: "#e65c00", // Darker orange when hovered
    },
    dropdownMenu: {
      display: showOptions ? "block" : "none",
      opacity: showOptions ? 1 : 0,
      visibility: showOptions ? "visible" : "hidden",
      transition: "opacity 0.3s ease-in-out, visibility 0s 0.3s", // Transition effect
      minWidth: "200px",
      backgroundColor: "#fff",
      border: "1px solid #ddd",
      borderRadius: "4px",
      position: "absolute",
      zIndex: 1000,
    },
    dropdownItem: {
      backgroundColor: "#f8f9fa",
      color: "#495057",
      padding: "10px 15px",
      border: "none",
      textAlign: "left",
      width: "100%",
      cursor: "pointer",
      transition: "background-color 0.3s ease, color 0.3s ease",
    },
    dropdownItemHover: {
      backgroundColor: "#ff6600", // Highlight with orange
      color: "white",
    },
  };
  return (
    <nav className="navbar navbar-expand-lg bg-light shadow-sm py-3">
      <div className="container">
        {/* Left Section: Animated Logo and Company Name */}
        <a className="navbar-brand d-flex align-items-center" href="/">
          <div className="logo-container">
            <img
              src={quationQ}
              alt="Company Logo"
              className="logo-img"
            />
          </div>
          <span className="fw-bold text-primary fs-4 ms-3">Recruit Pro</span>
        </a>
        <div
          className="dropdown d-inline-block"
          onMouseEnter={() => setShowOptions(true)} // Show dropdown on hover
          onMouseLeave={() => setShowOptions(false)} // Hide dropdown when mouse leaves both button and menu
        >
          {/* Main Administrator Login Button */}
          <button
            className="btn btn-orange dropdown-toggle"
            onClick={() => setShowOptions(!showOptions)}
          >
            Login As
          </button>

          {/* Dropdown Options */}
          {showOptions && (
            <div className="dropdown-menu show" aria-labelledby="dropdownMenuButton">
              <button className="dropdown-item" onClick={(e) => {
                e.preventDefault()
                navigate('/administration')
               }}>
                Admin
              </button>
              <button className="dropdown-item" onClick={(e) => {
                  e.preventDefault()
                  navigate('/candidate/login')
               }}>
                Candidate
              </button>
              <button className="dropdown-item" onClick={(e) => {
                e.preventDefault()
                navigate('/vendor/login')
               }}>
                Vendor
              </button>
              <button className="dropdown-item" onClick={(e) => { 
                e.preventDefault()
                navigate('/employee/login')
              }}>
                Employee
              </button>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}

export default LandingPageNavbar