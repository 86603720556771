import React,{ useState,useEffect} from 'react'
import bgpage from "./Images/bgpage.jpg"
import FooterPages from './FooterPages'
import Select from "react-select";
import UserService from '../services/UserService'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import getNotification from '../Redux/Action/action'
const {REACT_APP_UPLOAD_DATA}=process.env
const {REACT_APP_REDIRECT_URI}=process.env

function HiringManagerMaster() {
  const [addnewscreen,setaddnewscreen]=useState(false);
  const [addnewobject,setaddnewobject]=useState({})
  const [hiringmanagerlist,sethiringmanagerlist]=useState([])
  const [reporting_managerlist,setreporting_managerlist]=useState([])
  const [editobject,seteditobject]=useState({})
  const [searchobject,setsearchobject]=useState("")
const [filteredhiringmanagerlist,setfilteredhiringmanagerlist]=useState([])
  const dispatch=useDispatch()
  let [counter,setcounter]=useState(0);
  useEffect(()=>{
    handlefetch()
    handlefetchreporting_manager()
  },[])
  const handlefetchreporting_manager = async () => {
    if (UserService.isLoggedIn()) {
      try {
      
        const config = {
          method: "get",
          url: `${REACT_APP_UPLOAD_DATA}/reporting_managers`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },
     
        };
        const getResponse = await axios(config);
        console.log(getResponse);
        if (getResponse.data !== "Invalid User!") {
        handlefetch()
        setreporting_managerlist(getResponse.data)
          // setbrandoptions(getResponse.data);
          // setselectedbrand(getResponse.data[0].brand);
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/reporting_managers`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/hiringmanagermaster`,
        });
      }, 1000);
    }
  };
  const handlefetch = async () => {
    if (UserService.isLoggedIn()) {
      try {
      
        const config = {
          method: "get",
          url: `${REACT_APP_UPLOAD_DATA}/hiring_managers`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },
     
        };
        const getResponse = await axios(config);
        
        if (getResponse.data !== "Invalid User!") {
setfilteredhiringmanagerlist(getResponse.data)
        sethiringmanagerlist(getResponse.data)
          // setbrandoptions(getResponse.data);
          // setselectedbrand(getResponse.data[0].brand);
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/reporting_managers`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/hiringmanagermaster`,
        });
      }, 1000);
    }
  };
  const handleadd= async () => {
    if (UserService.isLoggedIn()) {
     if(addnewobject.hm_id && addnewobject.hm_name && addnewobject.rm_email_id && addnewobject.reporting_manager ){
      try {
        const FormData = require("form-data");
        const sendData = new FormData();
        sendData.append("hm_id",addnewobject.hm_id);
        sendData.append("hm_name",addnewobject.hm_name);
    sendData.append("hm_email_id",addnewobject.rm_email_id);
    sendData.append("reporting_manager",addnewobject.reporting_manager);
    sendData.append("status",addnewobject.status || "Active");
    sendData.append("created_by",UserService.getUsername())
        const config = {
          method: "post",
          url: `${REACT_APP_UPLOAD_DATA}/hiring_manager`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },
          data: sendData,
        };
        const getResponse = await axios(config);
    
        if (getResponse.data !== "Invalid User!") {
          document.getElementById("closemodal").click()
          setaddnewobject({hm_id:"",rm_email_id:"",hm_name:"",reporting_manager:"",status:"Active"})
          // setbrandoptions(getResponse.data);
          // setselectedbrand(getResponse.data[0].brand);
          handlefetch()
          dispatch(
            getNotification({
              message: "Hiring Manager successfully added",
              type: "success",
            })
          );
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/hiringmanagermaster`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
     }
     else{
      dispatch(
        getNotification({
          message: "Please fill all entries",
          type: "default",
        })
      );
     }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/hiringmanagermaster`,
        });
      }, 1000);
    }
  };
  
  const handleeditstatus=async (item)=>{
    if (UserService.isLoggedIn()) {
      if(true){
       try {
         const sendData = {
           hm_id: item.hm_id,
           hm_name: item.hm_name,
           hm_email_id: item.hm_email_id,
           status:item.status==="Active"?"Inactive":"Active",
           reporting_manager:item.reporting_manager,
         
           created_by:UserService.getUsername()
         };
         
         const config = {
           method: "put",
           url: `${REACT_APP_UPLOAD_DATA}/hiring_manager`,
           headers: {
             Accept: "application/json",  // API expects a JSON response
             "Content-Type": "application/json",  // Send data as JSON
           },
           data: JSON.stringify(sendData), // Convert your object to a JSON string
         };
         const getResponse = await axios(config);
       
         if (getResponse.data !== "Invalid User!") {
           // setbrandoptions(getResponse.data);
           // setselectedbrand(getResponse.data[0].brand);
           handlefetch()
           document.getElementById("closemodal2").click()
           seteditobject({rm_id:"",rm_email_id:"",rm_name:"",status:"Active"})
           dispatch(
             getNotification({
               message: "Hiring Manager successfully edited",
               type: "success",
             })
           );
         }
         else {
           UserService.doLogin({
             redirectUri: `${REACT_APP_REDIRECT_URI}/hiringmanagermaster`,
           });
         }
       } catch (err) {
         console.log("Server Error", err);
         if (err.response && err.response.status === 500) {
           dispatch(
             getNotification({
               message: "Server is Down! Please try again after sometime",
               type: "default",
             })
           );
         } else if (err.response && err.response.status === 400) {
           dispatch(
             getNotification({
               message: "Input is not in prescribed format",
               type: "default",
             })
           );
         } else if (err.response && err.response.status === 422) {
           dispatch(
             getNotification({
               message: "Input is not in prescribed format",
               type: "default",
             })
           );
         } else if (err.response && err.response.status === 404) {
           dispatch(
             getNotification({
               message: "Page not Found",
               type: "default",
             })
           );
         } else if (err.response && err.response.status === 401) {
           dispatch(
             getNotification({
               message: "Session expired! Please log in again",
               type: "default",
             })
           );
           setTimeout(UserService.doLogin(), 1000)
         } else {
           dispatch(
             getNotification({
               message: "Server is Down! Please try again after sometime",
               type: "default",
             })
           );
         }
       }
      }
      else{
       dispatch(
         getNotification({
           message: "Please fill all entries",
           type: "default",
         })
       );
      }
     } else {
       setTimeout(() => {
         UserService.doLogin({
           redirectUri: `${REACT_APP_REDIRECT_URI}/hiringmanagermaster`,
         });
       }, 1000);
     }   
  }
  const handleedit=async ()=>{
    if (UserService.isLoggedIn()) {
      if(editobject.hm_id && editobject.hm_name && editobject.hm_email_id  && editobject.status){
       try {
         const sendData = {
           hm_id: editobject.hm_id,
           hm_name: editobject.hm_name,
           hm_email_id: editobject.hm_email_id,
           reporting_manager:editobject.reporting_manager,
           status:editobject.status,
           created_by:UserService.getUsername()
         };
         
         const config = {
           method: "put",
           url: `${REACT_APP_UPLOAD_DATA}/hiring_manager`,
           headers: {
             Accept: "application/json",  // API expects a JSON response
             "Content-Type": "application/json",  // Send data as JSON
           },
           data: JSON.stringify(sendData), // Convert your object to a JSON string
         };
         const getResponse = await axios(config);
     
         if (getResponse.data !== "Invalid User!") {
           // setbrandoptions(getResponse.data);
           // setselectedbrand(getResponse.data[0].brand);
           handlefetch()
           document.getElementById("closemodal2").click()
           seteditobject({rm_id:"",rm_email_id:"",rm_name:"",status:"Active"})
           dispatch(
             getNotification({
               message: "Hiring Manager successfully edited",
               type: "success",
             })
           );
         }
         else {
           UserService.doLogin({
             redirectUri: `${REACT_APP_REDIRECT_URI}/hiringmanagermaster`,
           });
         }
       } catch (err) {
         console.log("Server Error", err);
         if (err.response && err.response.status === 500) {
           dispatch(
             getNotification({
               message: "Server is Down! Please try again after sometime",
               type: "default",
             })
           );
         } else if (err.response && err.response.status === 400) {
           dispatch(
             getNotification({
               message: "Input is not in prescribed format",
               type: "default",
             })
           );
         } else if (err.response && err.response.status === 422) {
           dispatch(
             getNotification({
               message: "Input is not in prescribed format",
               type: "default",
             })
           );
         } else if (err.response && err.response.status === 404) {
           dispatch(
             getNotification({
               message: "Page not Found",
               type: "default",
             })
           );
         } else if (err.response && err.response.status === 401) {
           dispatch(
             getNotification({
               message: "Session expired! Please log in again",
               type: "default",
             })
           );
           setTimeout(UserService.doLogin(), 1000)
         } else {
           dispatch(
             getNotification({
               message: "Server is Down! Please try again after sometime",
               type: "default",
             })
           );
         }
       }
      }
      else{
       dispatch(
         getNotification({
           message: "Please fill all entries",
           type: "default",
         })
       );
      }
     } else {
       setTimeout(() => {
         UserService.doLogin({
           redirectUri: `${REACT_APP_REDIRECT_URI}/hiringmanagermaster`,
         });
       }, 1000);
     }
  }
  const handlesearch=(value)=>{
    if(value?.length>3){
      setfilteredhiringmanagerlist( hiringmanagerlist.filter((item)=>
        item.hm_name.toLowerCase().includes(value.toLowerCase())
      ))
    
    }
    else{
      setfilteredhiringmanagerlist(hiringmanagerlist)
    }
    }
  return (
    <>
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div class="modal-header" style={{ backgroundColor: "#fafafa" }}>
              <h6 class="modal-title orangetheme" id="exampleModalLabel">
              Add New Record
              </h6>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closemodal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body p-4">
            <ol type="1">
        <li className='my-2'>
      <span className='row'>

      <label className='col-sm-4'>Employee Id</label>
      <input className="col-sm-8 form-control" placeholder='Employee Id' value={addnewobject.hm_id} onChange={(e)=>setaddnewobject({...addnewobject,hm_id:e.target.value})}/>
      </span>
          </li>
               <li className='my-2'>
          <span className='row'>
          <label className='col-sm-4'>Employee Name</label>
          <input className="col-sm-8 form-control" placeholder='Employee Name' value={addnewobject.hm_name} onChange={(e)=>setaddnewobject({...addnewobject,hm_name:e.target.value})}/>
        </span>  </li>
          
               <li className='my-2'>
          <span className='row'>
          <label className='col-sm-4'>Employee Email</label>
          <input className="col-sm-8 form-control" placeholder='Employee Email' value={addnewobject.rm_email_id} onChange={(e)=>setaddnewobject({...addnewobject,rm_email_id:e.target.value})}/>
       </span>   </li>
               <li className='my-2'>
          <span className='row'>
          <label className='col-sm-4'>Reporting Manager</label>
          <span className='col-sm-8  w-100'>
            <select className="form-select m-0" value={addnewobject.reporting_manager} onChange={(e)=>setaddnewobject({...addnewobject,reporting_manager:e.target.value})} >
             <option>Select</option>
     
              {reporting_managerlist?.map((item)=>{
                return <option>{item.rm_name}</option>
              })}
            </select>
          {/* <Select className="custom-select-width ml-5" onChange={(value)=>setaddnewobject({...addnewobject,reporting_manager:value.value})} options={ [{ value: 'raghavedra', label: 'raghavedra' },
]}  isSearchable   

/> */}
</span>
          </span>
          </li>
          <div class=" float-end form-check form-switch">

<input
className="form-check-input"
type="checkbox"
role="switch"
id="flexSwitchCheckChecked"
checked={addnewobject?.status === "Active" || addnewobject?.status == null}
onChange={()=>{if(addnewobject.status==="Active"){setaddnewobject({...addnewobject,status:"Inactive"})}else{
  setaddnewobject({...addnewobject,status:"Active"})
}}}
/>
<label class="form-check-label" for="flexSwitchCheckChecked">{addnewobject?.status === "Active" || addnewobject?.status == null?"Active":"Inactive"}</label>
</div>
             
      </ol>
     
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary btn-sm"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm"
                onClick={() => {
        
                   

                  handleadd()
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="exampleModal2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModal2Label"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div className="modal-content">
            <div class="modal-header" style={{ backgroundColor: "#fafafa" }}>
              <h6 class="modal-title orangetheme" id="exampleModal2Label">
                Edit Record
              </h6>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closemodal2"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body py-3 px-4">
            <ol type="1">
        <li className='my-2'>
      <span className='row'>

      <label className='col-sm-4'>Employee Id</label>
      <input className="col-sm-8 form-control" placeholder='Employee Id' value={editobject.hm_id} onChange={(e)=>seteditobject({...editobject,hm_id:e.target.value})}/>
      </span>
          </li>
               <li className='my-2'>
          <span className='row'>
          <label className='col-sm-4'>Employee Name</label>
          <input className="col-sm-8 form-control" placeholder='Employee Name' value={editobject.hm_name} onChange={(e)=>seteditobject({...editobject,hm_name:e.target.value})}/>
        </span>  </li>
          
               <li className='my-2'>
          <span className='row'>
          <label className='col-sm-4'>Employee Email</label>
          <input className="col-sm-8 form-control" placeholder='Employee Email' value={editobject.hm_email_id} onChange={(e)=>seteditobject({...editobject,hm_email_id:e.target.value})}/>
       </span>   </li>
       <li className='my-2'>
          <span className='row'>
          <label className='col-sm-4'>Reporting Manager</label>
          <span className='col-sm-8  w-100'>
            <select className="form-select m-0" value={editobject.reporting_manager} onChange={(e)=>seteditobject({...editobject,reporting_manager:e.target.value})} >
             <option>Select</option>
     
              {reporting_managerlist?.map((item)=>{
                return <option>{item.rm_name}</option>
              })}
            </select>
          {/* <Select className="custom-select-width ml-5" onChange={(value)=>setaddnewobject({...addnewobject,reporting_manager:value.value})} options={ [{ value: 'raghavedra', label: 'raghavedra' },
]}  isSearchable   

/> */}
</span>
          </span>
          </li>
       <div class=" float-end form-check form-switch">

<input
className="form-check-input"
type="checkbox"
role="switch"
id="flexSwitchCheckChecked"
checked={editobject?.status === "Active" || editobject?.status == null}
onChange={()=>{if(editobject.status==="Active"){seteditobject({...editobject,status:"Inactive"})}else{
  seteditobject({...editobject,status:"Active"})
}}}
/>
<label class="form-check-label" for="flexSwitchCheckChecked">{editobject?.status === "Active" || editobject?.status == null?"Active":"Inactive"}</label>
</div>
      </ol>
     
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary btn-sm"
                data-dismiss="modal"
                id="closemodal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm"
                onClick={() => {
                
                handleedit()

                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    <div>
    {/* <div >
    <img src={bgpage} className='mx-auto'/>
    </div> */}
    <div className='container masterhomepage'  >
    <div className='p-3'>
      <div className='my-3'>
    <h4 className=' orangetheme'>Hiring Managers</h4>
    </div>
    <div className='d-flex flex-row-reverse my-3'>

<i class="fa fa-plus-circle mx-1 text-success" style={{fontSize:"25px"}}  data-toggle={ "modal"}
data-target="#exampleModal"></i>
 <div class="input-group input-group-sm  mx-4">
  <div class="input-group-prepend">
    <span class="input-group-text" id="inputGroup-sizing-sm">Search</span>
  </div>
  <input placeholder='Type atleast 3 characters to search' type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"  onChange={(e)=>{handlesearch(e.target.value)}} />
</div>
</div>
  {filteredhiringmanagerlist?.length>0?  <table className='table table-striped  rounded mt-4 table-sm'>
    <thead className="" style={{backgroundColor:"#F79548",color:"white"}}>
        
                <th>S. No.</th>
                <th>Employee ID</th>
                <th>Employee Name</th>
                <th>Email</th> 
                <th>Reporting Manager</th>
                <th>Status</th>
        <th></th>
           
        </thead>
        <tbody>
        {filteredhiringmanagerlist?.slice(counter*25,(counter+1)*25)?.map((item,index)=>{
            return <tr>
<td>{index+1}</td>
<td>{item.hm_id}</td>
<td>{item.hm_name}</td>
<td>{item.hm_email_id}</td>
<td>{item.reporting_manager}</td>
<td>
  <div class="form-check form-switch">

<input
className="form-check-input"
type="checkbox"
role="switch"
id="flexSwitchCheckChecked"
checked={item.status === "Active"}
onChange={()=>handleeditstatus(item)}
/>
<label class="form-check-label" for="flexSwitchCheckChecked">{item.status === "Active"?"Active":"Inactive"}</label>
</div>
</td>
<td> <i className='fa fa-edit' onClick={()=>{seteditobject(item)}} data-toggle={ "modal"}
     data-target="#exampleModal2"></i></td>
 
            </tr>          })}
            {/* <tr>
                <td>1</td>
                <td>Q090</td>
                <td>Aayushi Gupta</td>
                <td>aayushi@quation.in</td>
               <td>Raghvendra MS</td>
              
                </tr>
                <tr>
                <td>2</td>
                <td>Q095</td>
                <td>Aishwarya</td>
                <td>aishwarya@quation.in</td>
               <td>Raghvendra MS</td>
       
               
                </tr>
                <tr>
                <td>3</td>
                <td>Q093</td>
                <td>Deeptha</td>
                <td>deeptha@quation.in</td> 
               <td>Raghvendra MS</td>
            
                </tr> */}

        </tbody>
     </table>:<div>There are currently no records to display!!!</div>}
    {hiringmanagerlist?.length>0 &&  <div className='d-flex justify-content-between'>
      { <button disabled={counter===0} className='btn' onClick={()=>setcounter(counter-1)}>Previous</button>} 
      { <button disabled={hiringmanagerlist?.length/25===(counter+1)} className='btn' onClick={()=>setcounter(counter+1)}>Next</button>}
      </div>}
    </div></div>
    </div>
   <FooterPages/> </>
  )
}

export default HiringManagerMaster