import React from "react";
import Sidenav from "./Sidenav";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import UserService from "../services/UserService";
function Support() {
  const [loader, setLoader] = useState(false);
  const [validateFirstName, setValidateFirstName] = useState(false);
  const [validateLastName, setValidateLastName] = useState(false);
  const [validateEmail, setValidateEmail] = useState(false);
  const [issuetype, setissuetype] = useState("");
  const [issuesubtype, setissuesubtype] = useState("");

  const handleissuetype = (e) => {
    setissuetype(e.target.value);
  };
  const handlesubissuetype = (e) => {
    setissuesubtype(e.target.value);
  };
  const sendData = async (e) => {
    e.preventDefault();
    const name = document.getElementById("name").value.trim();
    const email = document.getElementById("email").value.trim();
    const organisation = document.getElementById("organisation").value.trim();
    const country = document.getElementById("Country").value.trim();
    const phone = document.getElementById("phone").value.trim();
    const issue_type = document.getElementById("issuetype").value.trim();
    const sub_issuetype = document.getElementById("subissuetype").value.trim();
    const details = document.getElementById("details").value.trim();
    const ticketid = uuidv4();

    const cleanData = () => {
      document.getElementById("name").value = "";
      document.getElementById("email").value = "";
      document.getElementById("phone").value = "";
      document.getElementById("organisation").value = "";
      document.getElementById("country").value = "";
      document.getElementById("issuetype").value = "";
      document.getElementById("subissuetype").value = "";
      document.getElementById("details").value = "";
    };
  if(issue_type!=="Select" && sub_issuetype!=="Select" && details ){
   const emailBody = `

Dear Support Team,

I have recently generated Ticket ID: 3aa63ede-e10d-4f91-a0f9-8956fcdd8aec to address certain issues encountered within the application. Please find the details of the issue below:
   
   Issue Type:${issue_type}
   Sub Issue Type:${sub_issuetype}
   Details:${details}

I would greatly appreciate your prompt attention to this matter. For your convenience, I have attached relevant documents/screenshots.
   
   Best regards,
   ${name}
   ${organisation} ${country}
   ${phone}
  
            `;
         window.location.href = `mailto:support@organization.com?subject=Inquiry/Support Assistance Required - Ticket ID: ${ticketid} &body=${encodeURIComponent(
        emailBody
      )}`;
  }
    else {
      if (name === "") {
        setValidateFirstName(true);
        document.getElementById("name").focus();
        setTimeout(() => {
          setValidateFirstName(false);
        }, 3000);
      } else if (email === "") {
        setValidateEmail(true);
        document.getElementById("email").focus();
        setTimeout(() => {
          setValidateEmail(false);
        }, 3000);
      }   else if (issue_type === "Select") {
        alert("Please provide issue type");
      } else if (sub_issuetype === "Select") {
        alert("Please provide sub issue type");
      }
      else if (details === "") {
        alert("Please provide issue details.");
      }
   
    }
  };
  return (
    <>
     <div className="container">
     <div className="row">
      
      <div className="col-sm-10 backmodes">
        <h2 className="my-4 orangetheme">Support </h2>
 
        Create a new support ticket to get assistance with technical or non
        technical issues.Complete the details as required by selecting options
        that best describe your problem.
        <p>
          Providing detailed & accurate information helps us to resolve your
          issues faster.
        </p>
        <div className=" row mt-4">
          <div className="">
            <form className="col-sm-6 ">
              <h5>Generate your ticket now!</h5>
              <div>
                <div className="form-floating mb-3  contactSpace">
                  <select
                    id="issuetype"
                    className="form-select"
                    defaultValue="Issue Type"
                    placeholder="Issue Type"
                    onChange={(e) => handleissuetype(e)}
                  >
                    <option selected>Select</option>
                    <option>Technical</option>
                    <option>Non Technical</option>
                  </select>
                  <label htmlFor="floatingInput">
                    Issue Type<span className="text-danger">*</span>
                  </label>
                </div>
                <div className="form-floating mb-3  contactSpace">
                  <select
                    id="subissuetype"
                    className="form-select"
                    defaultValue="Issue Sub Type"
                    placeholder="Issue Sub Type"
                    onChange={(e) => handlesubissuetype(e)}
                  >
                    <option selected>Select</option>
                    {issuetype === "Technical" ? (
                      <>
                        <option>Competitive Analysis Issues</option>
                        <option>Brand Analysis Issues</option>
                        <option>Simulator Issues</option>
                        <option>Optimizer Issues</option>
                      </>
                    ) : (
                      <>
                        <option>Profile Issues</option>
                        <option>Database Configuration</option>
                        <option>Subscription</option>
                        
                      </>
                    )}
                  </select>
                  <label htmlFor="floatingInput">
                    Issue Sub Type<span className="text-danger">*</span>
                  </label>
                </div>
              </div>
              <div className=" mb-3 ">
                <textarea
                  className="form-control mt-2 "
                  type="text"
                  rows={5}
                  id="details"
                  placeholder="Details of your issue/Feedback"
                />
              </div>
              <div className="d-none form-floating mb-3 mt-3 contactSpace">
                <input
                  type="text"
                  className="d-none form-control"
                  id="name"
                  placeholder=" Name"
                  defaultValue={UserService.getFullName()}
                />
                <label htmlFor="floatingInput">
                  Name<span className="text-danger">*</span>
                </label>
                <div
                  id=""
                  className={validateFirstName ? "text-danger" : "d-none"}
                >
                  Please Fill Name.
                </div>
              </div>
              <div className="d-none form-floating mb-3 contactSpace">
                <input
                  id="email"
                  defaultValue={UserService.getEmail()}
                  className=" form-control"
                  placeholder="name@example.com"
                />
                <label htmlFor="floatingInput">
                  Email Address<span className="text-danger">*</span>
                </label>
                <div
                  id=""
                  className={validateEmail ? "text-danger" : "d-none"}
                >
                  Please Fill Email Address.
                </div>
              </div>

              <div className="d-none form-floating mb-3 contactSpace">
                <input
                  type="text"
                  className="d-none form-control"
                  id="organisation"
                  defaultValue="Quation"
                  placeholder="name@example.com"
                />
                <label htmlFor="floatingInput">Organisation</label>
              </div>
              <div className="form-floating mb-3 contactSpace">
                <input
                  type="text"
                  className=" d-none form-control"
                  id="Country"
                  defaultValue={"India"}
                  placeholder="name@example.com"
                />
       
              </div>

              <div className="form-floating mb-3 contactSpace">
                <input
                  type="tel"
                  className="form-control"
                  id="phone"
                  placeholder="9652415263"
                />
                <label htmlFor="floatingInput">Phone Number </label>
              </div>
              
              {/* <div className=" mb-3 ">
              <input
                type="file"
                className="form-control"
                id="file"
                placeholder="name@example.com"
              />
            </div> */}
              <button
                id="trynow"
                onClick={(e) => {
                  sendData(e);
                }}
                className="btn btn-primary my-4"
              >
                {loader ? (
                  <div className="spinner-border" role="status"></div>
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
     </div>
    </>
  );
}

export default Support;
