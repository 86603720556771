import React, { useState, useEffect } from 'react'
import Navbar2 from './Navbar2'
import FooterPages from './FooterPages'
import Select from "react-select";
import bgpage from "./Images/bgpage.jpg"
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import UserService from '../services/UserService'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import getNotification from '../Redux/Action/action'
const { REACT_APP_UPLOAD_DATA } = process.env
const { REACT_APP_REDIRECT_URI } = process.env
const {REACT_APP_ADMIN_USER}=process.env
const APPROVER_USERS = JSON.parse(process.env.REACT_APP_APPROVER_USERS || '[]');
const {REACT_APP_RECRUITMENT_HEAD}=process.env
function DashboardRH() {
  const navigate = useNavigate()
  const location = useLocation()
  
  const [filterstatus, setfilterstatus] = useState("Select")
  const [jobpostingfilteredlist, setjobpostingfilteredlist] = useState([])
  const [skillsetlist, setskillsetlist] = useState([])
  const [technicalskillsoptions, settechnicalskillsoptions] = useState([])
  const [soft_skillsoptions, setsoft_skillsoptions] = useState([])
  const [newjobcreationscreen, setnewjobcreationscreen] = useState(false)
  const [newjobcreationvalue, setnewjobcreationvalue] = useState({})
  const [clientcity, setclientcity] = useState("");
  const [startdatestate, setstartdatestate] = useState("");
  const [enddatestate, setenddatestate] = useState("");
  const [statusfilter, setstatusfilter] = useState("");
  const [createdbyfilter, setcreatedbyfilter] = useState("");
const [myreportingmanager,setmyreportingmanager]=useState("")
  const [jobpostinglist, setjobpostinglist] = useState([])
  const [employeelist, setemployeelist] = useState([])
  const [job_positionlist, setjob_positionlist] = useState([])
  const [clientlist, setclientlist] = useState([])
  const [viewobject, setviewobject] = useState({});
  const [editobject, seteditobject] = useState({})
  const [editscreen, seteditscreen] = useState(false)
  const [reportingmanagerlist,setreportingmanagerlist]=useState([])
  const [jobrolelist, setjobrolelist] = useState([
    "Data Scientist/Data Engineers", "Account Managers/Senior Data Engineer", "Sales Head/Delivery Head", "Business Head"
  ])
  const [verticallist, setverticallist] = useState([
    "Technology", "CPG", "Pre-Sales", "HR", "Leadership", "Product", "Retail", "Retail-Sales", "Marketing", "Partnership", "CPG - Sales", "L & D", "Admin", "IT"
  ])
  const [designationlist, setdesignationlist] = useState([
    "Intern", "Consultant", "Senior Consultant", "Associate Manager", "Manager", "Senior Manager", "AVP-Assistant Vice President", "VP-Vice President", "SVP-Senior Vice President", "CXO"

  ])
  const [previewscreen, setpreviewscreen] = useState(false)
  const dispatch = useDispatch()
  const initialnewjobcreationvalue = {
    designation: "Select",
    title: "",
    job_position: "Select",
    jobtitle: "",
    mandatory_skillset: [],
    good_to_have_skillset: [],
    soft_skills: [],
    relevant_experience_min:0,
    relevant_experience_max:15,
    summary: "",
    job_type: "Full Time",
    reason_for_recruitment: "Fresh",
    notice_period_days: "Select",
    preferred_working_location: "Select",
    preferred_working_locationoth: "",
    budget_min_range: "",
    budget_max_range: "",
    customer: "Select",
    customer_type: "Select",
    no_of_positions: "",
    panel_list: [],
    remarks:"",
    rm_id:"Select"
    // Add Slider values as well if applicable
  };

  useEffect(() => {
    handlefetch()
    handlefetchskills()
    handlefetchjob_positions()
    handlefetchclientlist()
    handlefetchemployees()
handlefetchreportingmanagers()
  }, [])
  const handlefetchreportingmanagers = async () => {
    if (UserService.isLoggedIn()) {
      try {
      
        const config = {
          method: "get",
          url: `${REACT_APP_UPLOAD_DATA}/reporting_managers`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },
     
        };
        const getResponse = await axios(config);
       
        if (getResponse.data !== "Invalid User!") {
   
        setreportingmanagerlist(getResponse.data)
      
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/reporting_managers`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
        });
      }, 1000);
    }
  };
  const handlefetchemployees = async () => {
    if (UserService.isLoggedIn()) {
      try {

        const config = {
          method: "get",
          url: `${REACT_APP_UPLOAD_DATA}/employee`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },

        };
        const getResponse = await axios(config);
        //console.log(getResponse);
        if (getResponse.data !== "Invalid User!") {

          setemployeelist(getResponse?.data?.map((it) => {
            return { label: it.emp_name, value: it.emp_name }
          }))

          setmyreportingmanager(getResponse?.data?.filter((it)=>it.emp_id?.toLowerCase()===UserService.getUsername().toLowerCase())[0].reporting_manager)
          console.log(getResponse?.data?.filter((it)=>it.emp_id?.toLowerCase()===UserService.getUsername().toLowerCase())[0].reporting_manager)
          
          // setbrandoptions(getResponse.data);
          // setselectedbrand(getResponse.data[0].brand);
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/reporting_managers`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
        });
      }, 1000);
    }
  };
  const handlefiltervariables = (type, value) => {
    let filteredList = [...jobpostinglist]

    if (type === "status") {
      setstatusfilter(value)
    }
    else if (type === "created_by") {
      setcreatedbyfilter(value)
    }
    else if (type === "startdate") {
      setstartdatestate(value)
    }
    else if (type === "enddate") {
      setenddatestate(value)
    }

  }
  const handlefilteroperations = () => {
    let filteredList = jobpostinglist;
  
    // Filter by status
    if (statusfilter && statusfilter !== "Select") {
      filteredList = filteredList.filter((it) =>
        it.status.toLowerCase() === statusfilter.toLowerCase()
      );
    }
  
    // Filter by created_by
    if (createdbyfilter && createdbyfilter !== "Select") {
      filteredList = filteredList.filter((it) =>
        it.created_by === createdbyfilter
      );
    }
  
    // Filter by date range (start date and end date)
    if (startdatestate && enddatestate) {
      const startDate = new Date(startdatestate);
      const endDate = new Date(enddatestate);
  
      if (startDate > endDate) {
        setstartdatestate("");
        setenddatestate("");
        dispatch(getNotification({
          message: "Start Date is greater than end date",
          type: "danger"
        }));
        return;  // Stop execution if invalid date range
      }
  
      filteredList = filteredList.filter((item) => {
        const dateCreated = new Date(item.date_created.split(" ")[0]);
        return dateCreated >= startDate && dateCreated <= endDate;
      });
    }
  
    // Sort filtered list by date_created (descending order)
    const sortedList = filteredList.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));
  
    // Update the filtered list in the state
    setjobpostingfilteredlist(sortedList);
  };
  
  const handlefetchclientlist = async () => {
    if (UserService.isLoggedIn()) {
      try {

        const config = {
          method: "get",
          url: `${REACT_APP_UPLOAD_DATA}/getclient`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },

        };
        const getResponse = await axios(config);
       // console.log(getResponse);
        if (getResponse.data !== "Invalid User!") {

          setclientlist(getResponse.data)

          // setbrandoptions(getResponse.data);
          // setselectedbrand(getResponse.data[0].brand);
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
        });
      }, 1000);
    }
  };
  const handlepreviewscreen = () => {
    if (newjobcreationvalue.designation && newjobcreationvalue.job_position
      && newjobcreationvalue.mandatory_skillset?.length > 0 &&
      newjobcreationvalue.good_to_have_skillset?.length > 0
      && newjobcreationvalue.soft_skills?.length > 0
      && newjobcreationvalue.relevant_experience_min && newjobcreationvalue.relevant_experience_max &&
      newjobcreationvalue.summary &&
      newjobcreationvalue.job_type && newjobcreationvalue.reason_for_recruitment
      && newjobcreationvalue.notice_period_days && newjobcreationvalue.notice_period_days !== "Select"
      && newjobcreationvalue.preferred_working_location && newjobcreationvalue.budget_min_range && newjobcreationvalue.budget_max_range
      && newjobcreationvalue.customer && newjobcreationvalue.customer !== "Select" &&
      newjobcreationvalue.customer_type && newjobcreationvalue.customer_type !== "Select" &&
      newjobcreationvalue.no_of_positions
&& newjobcreationvalue.rm_id && newjobcreationvalue.remarks

    ) {

      if (newjobcreationvalue.job_position === "Others") {
        if (newjobcreationvalue.jobtitle && newjobcreationvalue.jobtitle.trim() !== "") {
          setpreviewscreen(true);

        } else {
          dispatch(getNotification({
            message: "Please fill all entries",
            type: "default",
          }));
        }
      }
      else {
        setpreviewscreen(true);

      }

    }
    else {
      setpreviewscreen(false)
      dispatch(getNotification({
        message: "Please fill all entries",
        type: "default",
      }))
    }
  }
  const handlefetchjob_positions = async () => {
    if (UserService.isLoggedIn()) {
      try {


        const config = {
          method: "get",
          url: `${REACT_APP_UPLOAD_DATA}/job_position_master`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },

        };
        const getResponse = await axios(config);
        //console.log(getResponse);
        if (getResponse.data !== "Invalid User!") {
          // setbrandoptions(getResponse.data);
          // setselectedbrand(getResponse.data[0].brand);
          setjob_positionlist(getResponse.data)
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
        });
      }, 1000);
    }
  };
  const formatdate = (date) => {
    if (date === "open" || date === "Open" || typeof (date) !== "string" || date === null) { return "Open" }
    else {
      const onlydate = String(date.split(" ")[0])


      return `${onlydate.split("-")[2]}-${onlydate.split("-")[1]}-${onlydate.split("-")[0]}`
    }
  }
  const formattime = (time) => {
    if (time === "open" || time === "Open" || typeof (time) !== "string" || time === null) { return "Open" }
    else {

      return String(time.slice(11, 19))
    }
  }
  const capitalizefirstleter = (str) => {

    if (str?.length === 0 || str === null) return "Open"; // Check if the string is empty
    return str.charAt(0).toUpperCase() + str.slice(1);
  }


  const handlefetchskills = async () => {
    if (UserService.isLoggedIn()) {
      try {

        const config = {
          method: "get",
          url: `${REACT_APP_UPLOAD_DATA}/skills`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },

        };
        const getResponse = await axios(config);
       // console.log(getResponse);
        if (getResponse.data !== "Invalid User!") {
          let arrt = []
          let arrs = []
          {
            getResponse.data?.filter((it) => it.skill_type === "Technical").map((item) => {
              item.skill.split(",")?.map((it, index) => {
                arrt.push({ label: it, value: it })
              })

            })
          }
          {
            getResponse.data?.filter((it) => it.skill_type === "Soft").map((item) => {
              item.skill.split(",")?.map((it, index) => {
                arrs.push({ label: it, value: it })
              })

            })
          }

          settechnicalskillsoptions(arrt)
          setsoft_skillsoptions(arrs)

          // setbrandoptions(getResponse.data);
          // setselectedbrand(getResponse.data[0].brand);
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/job_positionmaster`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/job_positionmaster`,
        });
      }, 1000);
    }
  };
  const handlefetch = async () => {
    if (UserService.isLoggedIn()) {
      try {

        const config = {
          method: "get",
          url: `${REACT_APP_UPLOAD_DATA}/job_postings`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },

        };
        const getResponse = await axios(config);
       // console.log(getResponse);
        if (getResponse.data !== "Invalid User!") {
          setnewjobcreationvalue(initialnewjobcreationvalue)
          setjobpostinglist(getResponse.data.filter((it)=>it.status==="Under Screening").sort((a, b) =>new Date(b.date_created) - new Date(a.date_created)))
          setjobpostingfilteredlist(getResponse.data.filter((it)=>it.status==="Under Screening").sort((a, b) =>  new Date(b.date_created) - new Date(a.date_created)))
          
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
        });
      }, 1000);
    }
  };
  const handleadd = async () => {
    if (UserService.isLoggedIn()) {


      try {
        const FormData = require("form-data");
        const sendData = new FormData();


        sendData.append("job_position", newjobcreationvalue.job_position === "Others" ? `Others ${newjobcreationvalue.jobtitle}` : newjobcreationvalue.job_position);
        sendData.append("mandatory_skillset", newjobcreationvalue.mandatory_skillset.join(","));
        sendData.append("good_to_have_skillset", newjobcreationvalue.good_to_have_skillset.join(","));
        sendData.append("soft_skills", newjobcreationvalue.soft_skills.join(","));
        sendData.append("panel_list", newjobcreationvalue.panel_list.join(","));
        sendData.append("relevant_experience_min", Number(newjobcreationvalue.relevant_experience_min))
        sendData.append("relevant_experience_max", Number(newjobcreationvalue.relevant_experience_max))
        sendData.append("notice_period_days", newjobcreationvalue.notice_period_days)
        sendData.append("job_type", newjobcreationvalue.job_type)
        sendData.append("reason_for_recruitment", newjobcreationvalue.reason_for_recruitment)
        newjobcreationvalue.preferred_working_location === "Client" ? sendData.append("preferred_working_location", `Client ${newjobcreationvalue.preferred_working_locationoth}`) : sendData.append("preferred_working_location", newjobcreationvalue.preferred_working_location)
        sendData.append("budget_min_range", newjobcreationvalue.budget_min_range)
        sendData.append("budget_max_range", newjobcreationvalue.budget_max_range)
        sendData.append("customer_type", newjobcreationvalue.customer_type)
        sendData.append("customer", newjobcreationvalue.customer)
        sendData.append("no_of_positions", newjobcreationvalue.no_of_positions)
        sendData.append("date_closed", "")
        sendData.append("status", "Under Screening")
        sendData.append("designation", newjobcreationvalue.designation)
        sendData.append("summary", newjobcreationvalue.summary)
        sendData.append("created_by", UserService.getFullName());
        sendData.append("remarks", newjobcreationvalue.remarks);
        sendData.append("rm_id", newjobcreationvalue.rm_id);
        //console.log(sendData)

        const config = {
          method: "post",
          url: `${REACT_APP_UPLOAD_DATA}/job_posting`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },
          data: sendData,
        };
        const getResponse = await axios(config);
        //console.log(getResponse);
        if (getResponse.data !== "Invalid User!") {
          // setbrandoptions(getResponse.data);
          // setselectedbrand(getResponse.data[0].brand);

          
          //setnewjobcreationvalue(initialnewjobcreationvalue)
          setnewjobcreationscreen(false)
          handlefetch()
          document.getElementById("closemodal").click()
          dispatch(
            getNotification({
              message: "Job Posting generated successfully",
              type: "success",
            })
          );
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    }
    else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
        });
      }, 1000);
    }
  };
  const handleedit = async () => {
    if (UserService.isLoggedIn()) {


      try {
        const FormData = require("form-data");
        const sendData = new FormData();

sendData.append("job_id",editobject.job_id)
        sendData.append("job_position", editobject.job_position === "Others" ? `Others ${editobject.jobtitle}` : editobject.job_position);
        sendData.append("mandatory_skillset",typeof(editobject.mandatory_skillset==="string")?editobject.mandatory_skillset: editobject.mandatory_skillset.join(","));
        sendData.append("good_to_have_skillset", typeof(editobject.good_to_have_skillset)==="string"?editobject.good_to_have_skillset:editobject.good_to_have_skillset.join(","));
        sendData.append("soft_skills", typeof(editobject.soft_skills)==="string"?editobject.soft_skills:editobject.soft_skills.join(","));
        sendData.append("panel_list",typeof(editobject.panel_list)? editobject.panel_list:editobject.panel_list.join(","));
        sendData.append("relevant_experience_min", Number(editobject.relevant_experience_min))
        sendData.append("relevant_experience_max", Number(editobject.relevant_experience_max))
        sendData.append("notice_period_days", editobject.notice_period_days)
        sendData.append("job_type", editobject.job_type)
        sendData.append("reason_for_recruitment", editobject.reason_for_recruitment)
        editobject.preferred_working_location === "Client" ? sendData.append("preferred_working_location", `Client ${editobject.preferred_working_locationoth}`) : sendData.append("preferred_working_location", editobject.preferred_working_location)
        sendData.append("budget_min_range", editobject.budget_min_range)
        sendData.append("budget_max_range", editobject.budget_max_range)
        sendData.append("customer_type", editobject.customer)
        sendData.append("customer", editobject.customer_type)
        sendData.append("no_of_positions", editobject.no_of_positions)
        sendData.append("date_closed", "")
        sendData.append("status",editobject.status)
        sendData.append("designation", editobject.designation)
        sendData.append("summary", editobject.summary)
        sendData.append("created_by", UserService.getFullName());
        sendData.append("remarks",editobject.remarks)
        sendData.append("rm_id",editobject.rm_id)
        //console.log(sendData)

        const config = {
          method: "put",
          url: `${REACT_APP_UPLOAD_DATA}/job_posting`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },
          data: sendData,
        };
        const getResponse = await axios(config);
        //console.log(getResponse);
        if (getResponse.data !== "Invalid User!") {
          // setbrandoptions(getResponse.data);
          // setselectedbrand(getResponse.data[0].brand);

        
          setnewjobcreationvalue(initialnewjobcreationvalue)
          setnewjobcreationscreen(false)
          
          document.getElementById("closemodal3").click()
          dispatch(
            getNotification({
              message: "Job Posting edited successfully",
              type: "success",
            })
          );
          handlefetch()
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    }
    else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
        });
      }, 1000);
    }
  };



  return (
    <>
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div class="modal-header" style={{ backgroundColor: "#fafafa" }}>
              <h6 class="modal-title orangetheme" id="exampleModalLabel">
                Preview
              </h6>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closemodal"
                onClick={() => setpreviewscreen(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body p-4">


              <div className='my-2'>
                Details entered mentioned below:
                <span className='row p-3'>

                  <table className="table table-sm table-striped">
                    {/* <thead>
    <tr>
      <th scope="col">Field</th>
      <th scope="col">Value</th>
    </tr>
  </thead> */}
                    <tbody>
                      <tr>
                        <th scope="row">Designation<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.designation}</td>
                      </tr>
                      <tr>
                        <th scope="row">Job Title<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.job_position === "Others" ? `Others ${newjobcreationvalue.jobtitle}` : newjobcreationvalue.job_position}</td>
                      </tr>

                      <tr>
                        <th scope="row">Mandatory Skills<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.mandatory_skillset?.join(",")}</td>
                      </tr>
                      <tr>
                        <th scope="row">Good to Have Skills<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.good_to_have_skillset?.join(",")}</td>
                      </tr>
                      <tr>
                        <th scope="row">Soft Skills<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue?.soft_skills}</td>
                      </tr>
                      <tr>
                        <th scope="row">Summary<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue?.summary}</td>
                      </tr>
                      <tr>
                        <th scope="row">Experience(in years)<span className="text-danger">*</span> </th>
                        <td> {newjobcreationvalue.relevant_experience_min}-{newjobcreationvalue.relevant_experience_max}</td>
                      </tr>
                      <tr>
                        <th scope="row">Notice Period (in days)<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.notice_period_days}</td>
                      </tr>
                      <tr>
                        <th scope="row">Job Type<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.job_type}</td>
                      </tr>
                      <tr>
                        <th scope="row">Reason of Recruitment<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.reason_for_recruitment}</td>
                      </tr>
                      <tr>
                        <th scope="row">Preferred Working Location<span className="text-danger">*</span></th>
                        <td>
                          {newjobcreationvalue.preferred_working_location === "Client" ? `${newjobcreationvalue.preferred_working_location} ${newjobcreationvalue.preferred_working_locationoth}` : newjobcreationvalue.preferred_working_location}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Budget Band(in lacs)<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.budget_min_range}-{newjobcreationvalue.budget_max_range}</td>
                      </tr>
                      <tr>
                        <th scope="row">Customer<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.customer_type}</td>
                      </tr>
                      <tr>
                        <th scope="row">Customer Name<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.customer}</td>
                      </tr>
                      <tr>
                        <th scope="row">No. of Positions<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.no_of_positions}</td>
                      </tr>
                      <tr>
                        <th scope="row">Panelist</th>
                        <td>{newjobcreationvalue?.panel_list?.join(",")}</td>
                      </tr>
                      <tr>
                        <th scope="row">Reporting Manager<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue?.rm_id}</td>
                      </tr>
                      <tr>
                        <th scope="row">Remarks<span className="text-danger">*</span></th>
                        <td>{newjobcreationvalue.remarks}</td>
                      </tr>
                    </tbody>
                  </table>
                  Please check before submitting!
                  {/* <label className='col-sm-12 '>Designation: {newjobcreationvalue.designation}</label>
          <label className='col-sm-12 '>Job Position: {newjobcreationvalue.job_position}</label>
          <label className='col-sm-12 '>Title of job: {newjobcreationvalue.jobtitle}</label>
          <label className='col-sm-12 '>Mandatory Skills: {newjobcreationvalue.mandatory_skillset}</label>
          <label className='col-sm-12 '>Good to Have Skills: {newjobcreationvalue.good_to_have_skillset}</label>
          <label className='col-sm-12 '>Soft Skills: {newjobcreationvalue.soft_skills}</label>
          <label className='col-sm-12 '>Summary: {newjobcreationvalue.summary}</label>
          <label className='col-sm-12 '>Experience Min: {relevant_experience_min} Max: {relevant_experience_max}</label>
          <label className='col-sm-12 '>Notice Period(in days): {newjobcreationvalue.notice_period_days}</label>
          <label className='col-sm-12 '>Job Type: {newjobcreationvalue.job_type}</label>
          <label className='col-sm-12 '>Reason of Recruitment: {newjobcreationvalue.reason_for_recruitment}</label>
          <label className='col-sm-12 '>Preferred Working Location: {newjobcreationvalue.preferred_working_location==="Client"?newjobcreationvalue.preferred_working_locationoth:newjobcreationvalue.preferred_working_location}</label>
          <label className='col-sm-12 '>Budget Band: Min  {newjobcreationvalue.budget_min_range} Max  {newjobcreationvalue.budget_max_range}</label>
          <label className='col-sm-12 '>Customer: {newjobcreationvalue.customer}</label>
          <label className='col-sm-12 '>Customer Name: {newjobcreationvalue.customer_type}</label>
          <label className='col-sm-12 '>No. of Positions: {newjobcreationvalue.no_of_positions}</label> */}
                  {/* <input className="col-sm-8 form-control" placeholder='Add status' onChange={(e)=>setaddnewobject({...addnewobject,status:e.target.value})}/> */}

                </span>  </div>



            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary btn-sm"
                data-dismiss="modal"
                onClick={() => setpreviewscreen(false)}
              >
                Continue Editing
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm"
                onClick={() => {
                  handleadd()


                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="exampleModal2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModal2Label"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div class="modal-header" style={{ backgroundColor: "#fafafa" }}>
              <h6 class="modal-title orangetheme" id="exampleModal2Label">
                View Job Posting
              </h6>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closemodal2"

              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body p-4">


              <div className='my-2'>
                Details below:
                <span className='row p-3'>

                  <table className="table table-sm table-striped ">

                    <tbody>
                      <tr>
                        <th scope="row">Designation</th>
                        <td>{viewobject.designation}</td>
                      </tr>
                      <tr>
                        <th scope="row">Job Title</th>
                        <td>{viewobject.job_position}</td>
                      </tr>

                      <tr>
                        <th scope="row">Mandatory Skills</th>
                        <td>{viewobject.mandatory_skillset}</td>
                      </tr>
                      <tr>
                        <th scope="row">Good to Have Skills</th>
                        <td>{viewobject.mandatory_skillset}</td>
                      </tr>
                      <tr>
                        <th scope="row">Soft Skills</th>
                        <td>{viewobject.soft_skills}</td>
                      </tr>
                      <tr>
                        <th scope="row">Summary</th>
                        <td>{viewobject.summary}</td>
                      </tr>
                      <tr>
                        <th scope="row">Experience(in years) </th>
                        <td> {viewobject.relevant_experience_min}-{viewobject.relevant_experience_max}</td>
                      </tr>
                      <tr>
                        <th scope="row">Notice Period (in days)</th>
                        <td>{viewobject.notice_period_days}</td>
                      </tr>
                      <tr>
                        <th scope="row">Job Type</th>
                        <td>{viewobject.job_type}</td>
                      </tr>
                      <tr>
                        <th scope="row">Reason of Recruitment</th>
                        <td>{viewobject.reason_for_recruitment}</td>
                      </tr>
                      <tr>
                        <th scope="row">Preferred Working Location</th>
                        <td>
                          {viewobject.preferred_working_location}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Budget Band(in lacs)</th>
                        <td>{viewobject.budget_min_range}-{viewobject.budget_max_range}</td>
                      </tr>
                      <tr>
                        <th scope="row">Customer</th>
                        <td>{viewobject.customer_type}</td>
                      </tr>
                      <tr>
                        <th scope="row">Customer Name</th>
                        <td>{viewobject.customer}</td>
                      </tr>
                      <tr>
                        <th scope="row">No. of Positions</th>
                        <td>{viewobject.no_of_positions}</td>
                      </tr>
                      <tr>
                        <th scope="row">Panelist</th>
                        <td>{viewobject?.panel_list}</td>
                      </tr>
                      <tr>
                        <th scope="row">Reporting Manager</th>
                        <td>{reportingmanagerlist?.filter((it)=>it.rm_id===viewobject.rm_id)[0]?.rm_name}</td>
                      </tr>
                      <tr>
                        <th scope="row">Remarks</th>
                        <td>{viewobject.remarks}</td>
                      </tr>
                    </tbody>
                  </table>

                </span>  </div>



            </div>

          </div>
        </div>
      </div>
      <div
    class="modal fade"
    id="exampleModal3"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModal3Label"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div className="modal-content">
        <div class="modal-header" style={{ backgroundColor: "#fafafa" }}>
          <h6 class="modal-title orangetheme" id="exampleModal3Label">
            Edit Job Posting
          </h6>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            id="closemodal3"
            onClick={() => seteditscreen(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p-4">


          <div className='my-2'>
            Details entered mentioned below:
          

              <ol type="1" className='my-3'>

                <li className='mb-3'>
                  <div>Job ID</div>
                  <input className="form-control my-2" disabled placeholder='Job ID' value={editobject.job_id}/>
                </li>
                <li className='mb-3'>
                  <div>Designation<span className="text-danger">*</span></div>
                  <select className='my-2 form-select' value={editobject.designation} onChange={(e) => seteditobject({ ...editobject, designation: e.target.value })}>
                    <option>Select</option>
                    {designationlist?.map((item) => {
                      return <option>{item}</option>
                    })}

                  </select>

                </li>
   

                <li className='mb-3'>
                  <div>Job Title<span className="text-danger">*</span></div>

                  <div className='row'>
                    <select className='my-2 form-select col-sm mx-2' value={editobject.job_position?.includes("Others")?editobject.job_position?.split(" ")[0]:editobject.job_position} onChange={(e) => seteditobject({ ...editobject, job_position: e.target.value })}>
                      <option>Select</option>
                      {job_positionlist?.map((item) => {
                        return <option>{item.job_title}</option>
                      })}
                      <option>Others</option>
                    </select>
                    {editobject.job_position?.includes("Others") &&
                      <input className="form-control my-2 col-sm mx-1" placeholder='New Job Title ' value={editobject.jobtitle} onChange={(e) => seteditobject({ ...editobject, jobtitle: e.target.value,job_position:"Others" })} />
                    }
                  </div>
                </li>
                <li className='my-3'>
                  Skills
                  <ol type="a">
                    <li className='my-3'>
                      <div>Mandatory Skillset<span className="text-danger">*</span></div>


                      <Select className=" my-2 " options={technicalskillsoptions} isSearchable isMulti onChange={(value) => { seteditobject({ ...editobject, mandatory_skillset: value?.map((it) => it.value) }) }} 
                      value={typeof(editobject?.mandatory_skillset)==="string"? editobject?.mandatory_skillset?.includes(",")?editobject?.mandatory_skillset.split(",").map((it)=>{return { label:it,value:it}}):{label:editobject?.mandatory_skillset,value:editobject?.mandatory_skillset}:editobject?.mandatory_skillset?.map((it)=>{return {label:it,value:it}})} 
                        />


                    </li>
                    <li className='my-3'>
                      <div>Good to Have Skillset<span className="text-danger">*</span></div>


                      <Select className=" my-2 " options={technicalskillsoptions} isSearchable isMulti onChange={(value) => { seteditobject({ ...editobject, good_to_have_skillset: value?.map((it) => it.value) }) }} 
value={typeof(editobject?.good_to_have_skillset)==="string"? editobject?.good_to_have_skillset?.includes(",")?editobject?.good_to_have_skillset.split(",").map((it)=>{return { label:it,value:it}}):{label:editobject?.good_to_have_skillset,value:editobject?.good_to_have_skillset}:editobject?.good_to_have_skillset?.map((it)=>{return {label:it,value:it}})}
                       
                      />


                    </li>
                    <li className='my-3'>
                      <div>Soft Skills<span className="text-danger">*</span></div>


                      <Select className=" my-2 " options={soft_skillsoptions} isSearchable isMulti onChange={(value) => { seteditobject({ ...editobject, soft_skills: value?.map((it) => it.value) }) }}
value={typeof(editobject?.soft_skills)==="string"? editobject?.soft_skills?.includes(",")?editobject?.soft_skills.split(",").map((it)=>{return { label:it,value:it}}):{label:editobject?.soft_skills,value:editobject?.soft_skills}:editobject?.soft_skills?.map((it)=>{return {label:it,value:it}})}
                       
                       />


                    </li>
                  </ol>
                </li>
                <li className='my-3'>
                  <div>Summary<span className="text-danger">*</span></div>
                  <textarea rows="4" type="text" className='my-2 form-control' value={editobject.summary} onChange={(e) => seteditobject({ ...editobject, summary: e.target.value })} />
                </li>
                <li>
                  <div>Relevant Experience (in Years)<span className="text-danger">*</span></div>
                  <div className='row  my-2'>

                    <div className='col-sm text-start'>Min:</div>
                    <div className='col-sm text-start'>Max:</div>
                  </div>
                  <div className='d-flex justify-content-between my-auto'>
                    <input className="form-control mx-2" type="number" style={{ width: "100px" }} min={0} value={editobject.relevant_experience_min} onChange={(e) => seteditobject({ ...editobject, relevant_experience_min: e.target.value })}
                      max={15} />

                    <Slider className="mx-2 my-2" value={editobject.relevant_experience_min}
                      min={0} step={1} onChange={(e) => seteditobject({ ...editobject, relevant_experience_min: e.target.value })}
                      max={15} />

                    <input className="form-control mx-2 " type="number" style={{ width: "100px" }} min={0} value={editobject.relevant_experience_max} onChange={(e) => seteditobject({ ...editobject, relevant_experience_max: e.target.value })}
                      max={15} />
                    <Slider className="mx-2 my-2" value={editobject.relevant_experience_max}
                      min={0}
                      max={15} onChange={(e) => seteditobject({ ...editobject, relevant_experience_max: e.target.value })}/>
                  </div>

                  {/* <input className='form-control mx-2' placeholder='0' type="number" min={0} default={0}  max={15}/>
<input className='form-control mx-2' placeholder='15' type="number" min={0} default={0} max={15}/> */}


                </li>
                <li className='my-3'>
                  <div>Notice Period (in Days)<span className="text-danger">*</span></div>
                  <select className='form-select my-1' value={editobject.notice_period_days} onChange={(e) => seteditobject({ ...editobject, notice_period_days: e.target.value })}>
                    <option>Select</option>
                    <option>Immediate</option>
                    <option>15</option>
                    <option>30</option>
                    <option>60</option>
                    <option>90</option>

                  </select>
                  {/* <input className='form-control my-2' placeholder='Days' type="number" min={0} max={90} value={editobject.notice_period_days} onChange={(e)=>seteditobject({...editobject,notice_period_days:e.target.value})}  /> */}
                </li>
                <li className='my-3'>
                  <div>Job Type<span className="text-danger">*</span></div>

                  <div>
                    <input type='radio' checked={editobject.job_type==="Full Time"} name="job_type" className='mx-1' value="Full Time" onChange={(e) => { seteditobject({ ...editobject, job_type: e.target.value }) }} />
                    <label>Full Time</label>

                  </div>
                  <div> <input type='radio' checked={editobject.job_type==="Contractual"} name="job_type" className='mx-1' value="Contractual" onChange={(e) => seteditobject({ ...editobject, job_type: e.target.value })} /><label>Contractual</label></div>

                  {/* <input className='form-control my-2' placeholder='Days' type="number" min={0} max={90} value={editobject.notice_period_days} onChange={(e)=>seteditobject({...editobject,notice_period_days:e.target.value})}  /> */}
                </li>
                <li className='my-3'>
                  <div>Reason of recruitment?<span className="text-danger">*</span></div>
                  <div>
                    <input type='radio' name="reason_for_recruitment" checked={editobject.reason_for_recruitment==="Back Filled"} className='mx-1' value="Back Filled" onChange={(e) => seteditobject({ ...editobject, reason_for_recruitment: e.target.value })} />
                    <label>Back Filled</label>

                  </div>
                  <div> <input type='radio' checked={editobject.reason_for_recruitment==="Fresh"}  name="reason_for_recruitment" className='mx-1' value="Fresh" onChange={(e) => seteditobject({ ...editobject, reason_for_recruitment: e.target.value })} /><label>Fresh</label></div>

                  {/* <input className='form-control my-2' placeholder='Days' type="number" min={0} max={90} value={editobject.notice_period_days} onChange={(e)=>seteditobject({...editobject,notice_period_days:e.target.value})}  /> */}
                </li>

                <li className='my-3'>
                  <div>Preferred Working Location<span className="text-danger">*</span></div>
                  <select className='form-select my-2' value={typeof(editobject.preferred_working_location)==="string" &&  editobject.preferred_working_location.includes("Client")?editobject.preferred_working_location?.split(" ")[0]:editobject.preferred_working_location} onChange={(e) => { e.target.value !== "Select" && seteditobject({ ...editobject, preferred_working_location: e.target.value }) }}>
                    <option>
                      Select</option>
                    <option>Bangalore</option>
                    <option>Lucknow</option>
                    <option>Client</option>
                  </select>
                </li>
                {typeof(editobject.preferred_working_location)==="string" && editobject.preferred_working_location?.includes("Client") && <input className='form-control' placeholder="Client City" value={editobject.preferred_working_locationoth} onChange={(e) => {
                  { seteditobject({ ...editobject, preferred_working_locationoth: e.target.value,preferred_working_location:"Client" }) }
                }} />}

                <li className='my-3'><div>Budget Band (in lacs)<span className="text-danger">*</span></div>
                  <span className='d-flex justify-content-between my-2'>
                    <input className='form-control mx-2' placeholder='Min Budget Range' type="number" min={0} value={editobject.budget_min_range} onChange={(e) => { seteditobject({ ...editobject, budget_min_range: e.target.value }) }} />
                    <input className='form-control mx-2' placeholder='Max Budget Range' type="number" min={0} value={editobject.budget_max_range} onChange={(e) => { seteditobject({ ...editobject, budget_max_range: e.target.value }) }} />

                  </span>

                </li>
                <li className='mb-3'>
                  <div>Customer<span className="text-danger">*</span></div>
                  <select className='form-select my-2' placeholder='New Job Title ' value={editobject.customer_type} onChange={(e) => { seteditobject({ ...editobject, customer_type: e.target.value,customer:"" }); }}>
                    <option>Select</option>
                    <option>Client</option>
                    <option>Internal</option>
                    <option>Bench</option>
                  </select>
                  {editobject.customer_type === "Client" && <div>
                    <select className='form-select my-2' value={editobject.customer} onChange={(e) => seteditobject({ ...editobject, customer: e.target.value })}>
                      <option>Select</option>
                      {clientlist?.map((item) => {
                        return <option>{item.client_name}</option>
                      })}
                    </select>
                  </div>}
                  {editobject.customer_type === "Bench" &&
                    <select className="form-select my-2" value={editobject.customer} onChange={(e) => seteditobject({ ...editobject, customer: e.target.value })}>
                      <option>Select</option>
                      {verticallist?.map((item) => {
                        return <option>{item}</option>
                      })}
                    </select>
                  }
                  {editobject.customer_type === "Internal" &&
                    <select className="form-select my-2" value={editobject.customer} onChange={(e) => seteditobject({ ...editobject, customer: e.target.value })}>
                      <option>Select</option>
                      {verticallist?.map((item) => {
                        return <option>{item}</option>
                      })}
                    </select>
                  }

                </li>
                <li className='mb-3'>
                  <div>No of Positions<span className="text-danger">*</span></div>
                  <input className='form-select my-2' placeholder='No of vacancies' value={editobject.no_of_positions} onChange={(e) => seteditobject({ ...editobject, no_of_positions: e.target.value })} />


                </li>
                <li className='my-3'>
                  <div>Panelist</div>


                  <Select
  className="my-2"
  options={employeelist}
  isSearchable
  isMulti
  value={typeof editobject?.panel_list === "string"
    ? editobject?.panel_list?.includes(",")
      ? editobject?.panel_list.split(",").map((it) => ({ label: it, value: it }))
      : { label: editobject?.panel_list, value: editobject?.panel_list }
    : editobject?.panel_list?.map((it) => ({ label: it, value: it }))
  }
  onChange={(value) => {
    seteditobject((prevState) => ({
      ...prevState,
      panel_list: value?.map((it) => it.value),
    }));
  }}
/>



                </li>
              
                <li className='mb-3'>
                  <div>Reporting Manager</div>
                  <select className='form-select' value={editobject.rm_id} onChange={(e)=>{seteditobject({...editobject,rm_id:e.target.value})}}>
                  {UserService.getUsername()===REACT_APP_ADMIN_USER

?reportingmanagerlist?.map((item)=>{
                      return <option value={item.rm_id}>{item.rm_name}</option>
                    }):reportingmanagerlist.filter((item)=>item.rm_name===myreportingmanager)?.map((item)=>{
                      return <option value={item.rm_id}>{item.rm_name}</option>
                    })}
                    </select>
                </li>
                <li className='mb-3'>
                  <div>Remarks</div>
                  <input className="form-control my-2"  placeholder='Remarks' value={editobject.remarks} onChange={(e)=>{seteditobject({...editobject,remarks:e.target.value})}}/>
                </li>
                <li className='my-3'>
                  <div>Status<span className="text-danger">*</span></div>


<select  className="form-select" value={editobject.status} onChange={(e)=>{seteditobject({...editobject,status:e.target.value})}}>
 <>
  
  <option>Open</option>
  <option>Closed-Success</option>
  <option>Closed-Client Aborted</option>
  <option>Closed-Internal</option></> 

  

</select>


                </li>
              </ol>
              Please check before submitting!
     </div>



        </div>
        <div class="modal-footer">

          <button
            type="button"
            class="btn btn-primary btn-sm"
            onClick={() => {
              handleedit()


            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
      <div >
        {/* <img src={bgpage} className='mx-auto'/> */}
        <div className='container dashboard '  >
          <div className='p-4'>
            <div className='my-2 d-flex flex-row-reverse '>
              <button className='btn btn-primary  my-2' onClick={() => setnewjobcreationscreen(!newjobcreationscreen)}>{newjobcreationscreen ? "View already created jobs" : "Create a new Job"}</button>
            </div>
            <h4 className='orangetheme'> Job Postings</h4>
            {/* {!newjobcreationscreen ? <>     
  <h6>View, Update & Delete Job Postings</h6></>:  <h6>Create new Job postings</h6>} */}

            {newjobcreationscreen ?
              <div className='p-5 card'>
                <button className='btn btn-outline-danger btn-sm m-2 ' style={{ width: "75px" }} onClick={() => setnewjobcreationvalue(initialnewjobcreationvalue)}>Reset</button>

                <ol type="1" className='my-3'>

                  <li className='mb-3'>
                    <div>Job ID</div>
                    <input className="form-control my-2" disabled placeholder='Job ID' />
                  </li>
                  <li className='mb-3'>
                    <div>Designation<span className="text-danger">*</span></div>
                    <select className='my-2 form-select' value={newjobcreationvalue.designation} onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, designation: e.target.value })}>
                      <option>Select</option>
                      {designationlist?.map((item) => {
                        return <option>{item}</option>
                      })}

                    </select>

                  </li>
                  {/* <li className='mb-3'>
    <div>Job Role</div>
    <select className='my-2 form-select' value={newjobcreationvalue.jobrole} onChange={(e)=>setnewjobcreationvalue({...newjobcreationvalue,jobrole:e.target.value})}>
    <option>Select</option>
     {jobrolelist?.map((item)=>{
      return <option>{item}</option>
     })}
   
    </select>

  </li> */}

                  <li className='mb-3'>
                    <div>Job Title<span className="text-danger">*</span></div>

                    <div className='row'>
                      <select className='my-2 form-select col-sm mx-2' value={newjobcreationvalue.job_position} onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, job_position: e.target.value })}>
                        <option>Select</option>
                        {job_positionlist?.map((item) => {
                          return <option>{item.job_title}</option>
                        })}
                        <option>Others</option>
                      </select>
                      {newjobcreationvalue.job_position === "Others" &&
                        <input className="form-control my-2 col-sm mx-1" placeholder='New Job Title ' value={newjobcreationvalue.jobtitle} onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, jobtitle: e.target.value })} />
                      }
                    </div>
                  </li>
                  <li className='my-3'>
                    Skills
                    <ol type="a">
                      <li className='my-3'>
                        <div>Mandatory Skillset<span className="text-danger">*</span></div>


                        <Select className=" my-2 " options={technicalskillsoptions} isSearchable isMulti 
                        onChange={(value) => { setnewjobcreationvalue({ ...newjobcreationvalue, mandatory_skillset: value?.map((it) => it.value) }) }} value={newjobcreationvalue?.mandatory_skillset?.length > 0 && newjobcreationvalue?.mandatory_skillset?.map((it) => ({ label: it, value: it }))} />


                      </li>
                      <li className='my-3'>
                        <div>Good to Have Skillset<span className="text-danger">*</span></div>


                        <Select className=" my-2 " options={technicalskillsoptions} isSearchable isMulti onChange={(value) => { setnewjobcreationvalue({ ...newjobcreationvalue, good_to_have_skillset: value?.map((it) => it.value) }) }} value={newjobcreationvalue?.good_to_have_skillset?.length > 0 && newjobcreationvalue?.good_to_have_skillset?.map((it) => ({ label: it, value: it }))} />


                      </li>
                      <li className='my-3'>
                        <div>Soft Skills<span className="text-danger">*</span></div>


                        <Select className=" my-2 " options={soft_skillsoptions} isSearchable isMulti onChange={(value) => { setnewjobcreationvalue({ ...newjobcreationvalue, soft_skills: value?.map((it) => it.value) }) }} value={newjobcreationvalue?.soft_skills?.length > 0 && newjobcreationvalue?.soft_skills?.map((it) => ({ label: it, value: it }))} />


                      </li>
                    </ol>
                  </li>
                  <li className='my-3'>
                    <div>Summary<span className="text-danger">*</span></div>
                    <textarea rows="4" type="text" className='my-2 form-control' value={newjobcreationvalue.summary} onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, summary: e.target.value })} />
                  </li>
                  <li>
                    <div>Relevant Experience (in Years)<span className="text-danger">*</span></div>
                    <div className='row  my-2'>

                      <div className='col-sm text-start'>Min:</div>
                      <div className='col-sm text-start'>Max:</div>
                    </div>
                    <div className='d-flex justify-content-between my-auto'>
                      <input className="form-control mx-2" type="number" style={{ width: "100px" }} min={0} value={newjobcreationvalue.relevant_experience_min} onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, relevant_experience_min: e.target.value })}
                        max={15} default={0}/>

                      <Slider className="mx-2 my-2" value={newjobcreationvalue.relevant_experience_min}
                        min={0} step={1} default={0} onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, relevant_experience_min: e.target.value })}
                        max={15} />

                      <input className="form-control mx-2 " type="number" style={{ width: "100px" }} min={0} value={newjobcreationvalue.relevant_experience_max} onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, relevant_experience_max: e.target.value })}
                        max={15} default={15}/>
                      <Slider className="mx-2 my-2" value={newjobcreationvalue.relevant_experience_max}
                        min={0} default={15}
                        max={15} onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, relevant_experience_max: e.target.value })} />
                    </div>

                    {/* <input className='form-control mx-2' placeholder='0' type="number" min={0} default={0}  max={15}/>
 <input className='form-control mx-2' placeholder='15' type="number" min={0} default={0} max={15}/> */}


                  </li>
                  <li className='my-3'>
                    <div>Notice Period (in Days)<span className="text-danger">*</span></div>
                    <select className='form-select my-1' value={newjobcreationvalue.notice_period_days} onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, notice_period_days: e.target.value })}>
                      <option>Select</option>
                      <option>Immediate</option>
                      <option>15</option>
                      <option>30</option>
                      <option>60</option>
                      <option>90</option>

                    </select>
                    {/* <input className='form-control my-2' placeholder='Days' type="number" min={0} max={90} value={newjobcreationvalue.notice_period_days} onChange={(e)=>setnewjobcreationvalue({...newjobcreationvalue,notice_period_days:e.target.value})}  /> */}
                  </li>
                  <li className='my-3'>
                    <div>Job Type<span className="text-danger">*</span></div>

                    <div>
                      <input type='radio' checked={newjobcreationvalue.job_type==="Full Time"} name="job_type" className='mx-1' value="Full Time" onChange={(e) => { setnewjobcreationvalue({ ...newjobcreationvalue, job_type: e.target.value }) }} />
                      <label>Full Time</label>

                    </div>
                    <div> <input type='radio' name="job_type" className='mx-1' value="Contractual" onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, job_type: e.target.value })} /><label>Contractual</label></div>

                    {/* <input className='form-control my-2' placeholder='Days' type="number" min={0} max={90} value={newjobcreationvalue.notice_period_days} onChange={(e)=>setnewjobcreationvalue({...newjobcreationvalue,notice_period_days:e.target.value})}  /> */}
                  </li>
                  <li className='my-3'>
                    <div>Reason of recruitment?<span className="text-danger">*</span></div>
                    <div>
                      <input type='radio' name="reason_for_recruitment"  className='mx-1' value="Back Filled" onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, reason_for_recruitment: e.target.value })} />
                      <label>Back Filled</label>

                    </div>
                    <div> <input type='radio' name="reason_for_recruitment" className='mx-1' checked={newjobcreationvalue.reason_for_recruitment==="Fresh"} value="Fresh" onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, reason_for_recruitment: e.target.value })} /><label>Fresh</label></div>

                    {/* <input className='form-control my-2' placeholder='Days' type="number" min={0} max={90} value={newjobcreationvalue.notice_period_days} onChange={(e)=>setnewjobcreationvalue({...newjobcreationvalue,notice_period_days:e.target.value})}  /> */}
                  </li>

                  <li className='my-3'>
                    <div>Preferred Working Location<span className="text-danger">*</span></div>
                    <select className='form-select my-2' value={newjobcreationvalue.preferred_working_location} onChange={(e) => { e.target.value !== "Select" && setnewjobcreationvalue({ ...newjobcreationvalue, preferred_working_location: e.target.value }) }}>
                      <option>
                        Select</option>
                      <option>Bangalore</option>
                      <option>Lucknow</option>
                      <option>Client</option>
                    </select>
                  </li>
                  {newjobcreationvalue.preferred_working_location === "Client" && <input className='form-control' placeholder="Client City" onChange={(e) => {
                    if (newjobcreationvalue.preferred_working_location === "Client") { setnewjobcreationvalue({ ...newjobcreationvalue, preferred_working_locationoth: e.target.value }) }
                  }} />}

                  <li className='my-3'><div>Budget Band (in lacs)<span className="text-danger">*</span></div>
                    <span className='d-flex justify-content-between my-2'>
                      <input className='form-control mx-2' placeholder='Min Budget Range' type="number" min={0} value={newjobcreationvalue.budget_min_range} onChange={(e) => { setnewjobcreationvalue({ ...newjobcreationvalue, budget_min_range: e.target.value }) }} />
                      <input className='form-control mx-2' placeholder='Max Budget Range' type="number" min={0} value={newjobcreationvalue.budget_max_range} onChange={(e) => { setnewjobcreationvalue({ ...newjobcreationvalue, budget_max_range: e.target.value }) }} />

                    </span>

                  </li>
                  <li className='mb-3'>
                    <div>Customer<span className="text-danger">*</span></div>
                    <select className='form-select my-2' placeholder='New Job Title ' value={newjobcreationvalue.customer_type} onChange={(e) => { setnewjobcreationvalue({ ...newjobcreationvalue, customer_type: e.target.value, customer: "" }); }}>
                      <option>Select</option>
                      <option>Client</option>
                      <option>Internal</option>
                      <option>Bench</option>
                    </select>
                    {newjobcreationvalue.customer_type === "Client" && <div>
                      <select className='form-select my-2' value={newjobcreationvalue.customer} onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, customer: e.target.value })}>
                        <option>Select</option>
                        {clientlist?.map((item) => {
                          return <option>{item.client_name}</option>
                        })}
                      </select>
                    </div>}
                    {newjobcreationvalue.customer_type === "Bench" &&
                      <select className="form-select my-2" value={newjobcreationvalue.customer} onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, customer: e.target.value })}>
                        <option>Select</option>
                        {verticallist?.map((item) => {
                          return <option>{item}</option>
                        })}
                      </select>
                    }
                    {newjobcreationvalue.customer_type === "Internal" &&
                      <select className="form-select my-2" value={newjobcreationvalue.customer} onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, customer: e.target.value })}>
                        <option>Select</option>
                        {verticallist?.map((item) => {
                          return <option>{item}</option>
                        })}
                      </select>
                    }

                  </li>
                  <li className='mb-3'>
                    <div>No of Positions<span className="text-danger">*</span></div>
                    <input className='form-select my-2' placeholder='No of vacancies' value={newjobcreationvalue.no_of_positions} onChange={(e) => setnewjobcreationvalue({ ...newjobcreationvalue, no_of_positions: e.target.value })} />


                  </li>
                  <li className='my-3'>
                    <div>Panelist</div>


                    <Select className=" my-2 " options={employeelist} isSearchable isMulti onChange={(value) => { setnewjobcreationvalue({ ...newjobcreationvalue, panel_list: value?.map((it) => it.value) }) }} value={newjobcreationvalue?.panel_list?.length > 0 && newjobcreationvalue?.panel_list?.map((it) => ({ label: it, value: it }))} />


                  </li>
                  <li className='mb-3'>
                  <div>Reporting Manager<span className="text-danger">*</span></div>
                  <select className='form-select my-2' placeholder='Reporting Manager' value={newjobcreationvalue.rm_id} onChange={(e)=>{setnewjobcreationvalue({ ...newjobcreationvalue, rm_id: e.target.value })}}>
                    <option>Select</option>
                    {UserService.getUsername()===REACT_APP_ADMIN_USER

?reportingmanagerlist?.map((item)=>{
                      return <option value={item.rm_id}>{item.rm_name}</option>
                    }):reportingmanagerlist.filter((item)=>item.rm_name===myreportingmanager)?.map((item)=>{
                      return <option value={item.rm_id}>{item.rm_name}</option>
                    })}
                  </select>
                 
                </li>
                <li className='mb-3'>
                  <div>Remarks<span className="text-danger">*</span></div>
                  <input className="form-control my-2" placeholder='Remarks' value={newjobcreationvalue.remarks} onChange={(e)=>{setnewjobcreationvalue({ ...newjobcreationvalue, remarks: e.target.value })}}/>
                </li>
                </ol>
                <button className='btn btn-primary float-end' data-toggle={previewscreen ? "modal" : ""}
                  data-target="#exampleModal" onClick={() => { handlepreviewscreen() }}
                >
                  Preview</button>
              </div> :

 
  jobpostinglist?.length > 0 ?
                <div>
                  <div className=' px-4 py-3 my-4 '
                  // style={{border:"2px solid grey"}}
                  >
                    <div className='d-flex justify-content-between  '>
                      <div>Filter By <i className='fa fa-filter'></i>
                      </div>


                      <div>
                        Status:
                        <select className='mx-2 text-secondary rounded' value={statusfilter} onChange={(e) => { handlefiltervariables("status", e.target.value) }}>
                          <option>Select</option>
                          <option value="open">Open
                          </option>
                          <option value="closed">Closed</option></select>
                      </div>
                      <div>
                        Created By:
                        <select className='mx-2 text-secondary rounded' value={createdbyfilter} onChange={(e) => { handlefiltervariables("created_by", e.target.value) }}>
                          <option>Select</option>
                          {Array.from(new Set(jobpostinglist?.map((item) => { return item.created_by }))).map((it) => {
                            return <option>{it}</option>
                          })
                          }

                        </select>
                      </div>
                      <div>
                        Date Range:
                        <input className="mx-2 text-secondary rounded" style={{ border: "1px solid grey" }} type="date" value={startdatestate} onChange={(e) => { handlefiltervariables("startdate", e.target.value) }} />
                        <input className="mx-2 text-secondary rounded" style={{ border: "1px solid grey" }} type="date" value={enddatestate} onChange={(e) => { handlefiltervariables("enddate", e.target.value) }} />
                      </div>
                      <div>
                        <button className='btn btn-sm' style={{ backgroundColor: "#cfe2ff" }} onClick={() => handlefilteroperations()}>Apply Filters</button>
                      </div>
                    </div>
                  </div>
                  <div>
                    {jobpostingfilteredlist?.length > 0 ?
                      jobpostingfilteredlist?.map((item) => {
                        return <>
                          <div
                            className="row border rounded shadow mx-5 my-3   "
                            style={{ height: "77px" }}
                          >
                            <div
                              className="col-sm-4 rounded p-2 text-center"
                              style={{ background: "#fafafa" }}


                            >
                              <div className='position-absolute bg-success rounded-lg text-white p-1 text-sm' style={{ top: "0px", left: "0px", fontSize: "12px" }}>
                                {`${capitalizefirstleter(item.status)}`}
                              </div>
                              {/* <div>Job Id:{item.job_id}</div> */}
                              <div className='mt-3'>{item.job_position}</div>
                              <div className="">

                              </div>
                            </div>
                            <div className='col-sm-6 mt-3 '>
                              <div className='row'>
                                <div className='col-sm m-auto'>
                                  <div>Date Created: {formatdate(item.date_created)}</div>
                                  <div >Date Closed: {item.date_closed !== null ? formatdate(item.date_closed) : ""} </div>

                                </div>
                                <div className='col-sm'>
                                  <div> Created By: {item.created_by}</div>
                                  <div>  Status: {item.status}
                                  </div></div>
                              </div>

                            </div>
                            <div className='col-sm-2'>
                              <div className="mt-4 d-flex flex-row-reverse">

                                {/* <button className='btn btn-danger mx-1  btn-sm'>
                                  <i class="fa fa-trash"></i>

                                </button> */}
                                <button className='btn btn-primary mx-1 btn-sm' onClick={() => { seteditscreen(true);
                                 seteditobject({...item,jobtitle:item.job_position?.split(" ")?.slice(1).join(" "),preferred_working_locationoth:item.preferred_working_location?.split(" ")?.slice(1).join(" ") 
                                  
                                 })}}
                                  data-toggle="modal"
                                  data-target="#exampleModal3" >
                                  <i class="fa fa-edit"></i>
                                </button>
                                <button className='btn btn-success mx-1 btn-sm '>

                                  <i class="fa fa-eye" onClick={() => { setviewobject(item) }} data-toggle="modal"
                                    data-target="#exampleModal2"
                                  //data-toggle="collapse" data-target={`#collapseExample${item.job_id}`} aria-expanded="false" aria-controls={`#collapseExample${item.job_id}`}
                                  ></i>
                                </button>
                              </div>

                            </div>

                          </div>
                          {/* <div className=" collapse mx-5" id={`collapseExample${item.job_id}`}>
                            <table className="table table-sm table-striped ">
      
                              <tbody>
                                <tr>
                                  <th scope="row">Designation</th>
                                  <td>{item.designation}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Job Title</th>
                                  <td>{item.job_position}</td>
                                </tr>

                                <tr>
                                  <th scope="row">Mandatory Skills</th>
                                  <td>{item.mandatory_skillset}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Good to Have Skills</th>
                                  <td>{item.mandatory_skillset}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Soft Skills</th>
                                  <td>{item.soft_skills}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Summary</th>
                                  <td>{item.summary}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Experience(in years) </th>
                                  <td> {item.relevant_experience_min}-{item.relevant_experience_max}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Notice Period (in days)</th>
                                  <td>{item.notice_period_days}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Job Type</th>
                                  <td>{item.job_type}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Reason of Recruitment</th>
                                  <td>{item.reason_for_recruitment}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Preferred Working Location</th>
                                  <td>
                                    {item.preferred_working_location}
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Budget Band(in lacs)</th>
                                  <td>{item.budget_min_range}-{item.budget_max_range}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Customer</th>
                                  <td>{item.customer_type}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Customer Name</th>
                                  <td>{item.customer}</td>
                                </tr>
                                <tr>
                                  <th scope="row">No. of Positions</th>
                                  <td>{item.no_of_positions}</td>
                                </tr>
                                <tr>
                                  <th scope="row">panel_list</th>
                                  <td>{item?.panel_list}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div> */}
                        </>
                      }) : <div>There are no matching records</div>
                    }

                  </div>
                </div>
            : <div>There are no existing job postings</div>
            }

          </div>
        </div>
      </div>
      <FooterPages />
    </>
  )
}

export default DashboardRH