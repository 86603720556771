import React, { useState, useEffect } from 'react'
import bgpage from "./Images/bgpage.jpg"
import FooterPages from './FooterPages'
import UserService from '../services/UserService'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import getNotification from '../Redux/Action/action'
const { REACT_APP_UPLOAD_DATA } = process.env
const { REACT_APP_REDIRECT_URI } = process.env

function SkillsetMaster() {

  const [addnewscreen, setaddnewscreen] = useState(false);
  const [addnewobject, setaddnewobject] = useState({})
  const [skillsetlist, setskillsetlist] = useState([])
  const [editobject, seteditobject] = useState({})
  const [skillfilter, setskillfilter] = useState("Select")
  const [searchobject, setsearchobject] = useState("")
  const [filteredskillsetlist, setfilteredskillsetlist] = useState([])
  let counter = 1;
  const dispatch = useDispatch()

  useEffect(() => {
    handlefetch()
  }, [])

  const handlefetch = async () => {
    if (UserService.isLoggedIn()) {
      try {

        const config = {
          method: "get",
          url: `${REACT_APP_UPLOAD_DATA}/skills`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },

        };
        const getResponse = await axios(config);
        console.log(getResponse);
        if (getResponse.data !== "Invalid User!") {
          setfilteredskillsetlist(getResponse.data)
          setskillsetlist(getResponse.data)

          // setbrandoptions(getResponse.data);
          // setselectedbrand(getResponse.data[0].brand);
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/reporting_managers`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/skillsetmaster`,
        });
      }, 1000);
    }
  };
  const handleadd = async () => {
    if (UserService.isLoggedIn()) {
      if (addnewobject.skill && addnewobject.skill_type && addnewobject.skill_type !== "Select") {
        try {
          const FormData = require("form-data");
          const sendData = new FormData();
          sendData.append("skill", addnewobject.skill);
          sendData.append("created_by", UserService.getUsername());
          sendData.append("skill_type", addnewobject.skill_type);
          const config = {
            method: "post",
            url: `${REACT_APP_UPLOAD_DATA}/skill_master`,
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
            data: sendData,
          };
          const getResponse = await axios(config);
          console.log(getResponse);
          if (getResponse.data !== "Invalid User!") {
            // setbrandoptions(getResponse.data);
            // setselectedbrand(getResponse.data[0].brand);
            document.getElementById("closemodal").click()
            setaddnewobject({ skill: "", skill_type: "Select" })
            handlefetch()
            dispatch(
              getNotification({
                message: "Skill successfully added",
                type: "success",
              })
            );
          }
          else {
            UserService.doLogin({
              redirectUri: `${REACT_APP_REDIRECT_URI}/skillsetmaster`,
            });
          }
        } catch (err) {
          console.log("Server Error", err);
          if (err.response && err.response.status === 500) {
            dispatch(
              getNotification({
                message: "Server is Down! Please try again after sometime",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 400) {
            dispatch(
              getNotification({
                message: "Input is not in prescribed format",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 422) {
            dispatch(
              getNotification({
                message: "Input is not in prescribed format",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 404) {
            dispatch(
              getNotification({
                message: "Page not Found",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 401) {
            dispatch(
              getNotification({
                message: "Session expired! Please log in again",
                type: "default",
              })
            );
            setTimeout(UserService.doLogin(), 1000)
          } else {
            dispatch(
              getNotification({
                message: "Server is Down! Please try again after sometime",
                type: "default",
              })
            );
          }
        }
      }
      else {
        dispatch(
          getNotification({
            message: "Please fill all entries",
            type: "default",
          })
        );
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/skillsetmaster`,
        });
      }, 1000);
    }
  };
  const handleedit = async () => {
    if (UserService.isLoggedIn()) {
      if (editobject.skill && editobject.skill_type && editobject.skill_type !== "Select") {
        try {
          const FormData = require("form-data");
          const sendData = new FormData();
          sendData.append("skill", editobject.skill);
          sendData.append("created_by", UserService.getUsername());
          sendData.append("skill_type", editobject.skill_type);
          const config = {
            method: "put",
            url: `${REACT_APP_UPLOAD_DATA}/skill_master`,
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
            data: sendData,
          };
          const getResponse = await axios(config);
          console.log(getResponse);
          if (getResponse.data !== "Invalid User!") {
            // setbrandoptions(getResponse.data);
            // setselectedbrand(getResponse.data[0].brand);
            document.getElementById("closemodal2").click()
            setaddnewobject({ skill: "", skill_type: "" })
            handlefetch()
            dispatch(
              getNotification({
                message: "Skill successfully edited",
                type: "success",
              })
            );
          }
          else {
            UserService.doLogin({
              redirectUri: `${REACT_APP_REDIRECT_URI}/skillsetmaster`,
            });
          }
        } catch (err) {
          console.log("Server Error", err);
          if (err.response && err.response.status === 500) {
            dispatch(
              getNotification({
                message: "Server is Down! Please try again after sometime",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 400) {
            dispatch(
              getNotification({
                message: "Input is not in prescribed format",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 422) {
            dispatch(
              getNotification({
                message: "Input is not in prescribed format",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 404) {
            dispatch(
              getNotification({
                message: "Page not Found",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 401) {
            dispatch(
              getNotification({
                message: "Session expired! Please log in again",
                type: "default",
              })
            );
            setTimeout(UserService.doLogin(), 1000)
          } else {
            dispatch(
              getNotification({
                message: "Server is Down! Please try again after sometime",
                type: "default",
              })
            );
          }
        }
      }
      else {
        dispatch(
          getNotification({
            message: "Please fill all entries",
            type: "default",
          })
        );
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/skillsetmaster`,
        });
      }, 1000);
    }
  };
  const handlesearch = (value) => {
    if (value?.length > 3) {
      setfilteredskillsetlist(skillsetlist.filter((item) =>
        item.skill.toLowerCase().includes(value.toLowerCase())
      ))
      console.log(skillsetlist.filter((item) =>
        item.skill.toLowerCase().includes(value.toLowerCase())
      ))
    }
    else {
      setfilteredskillsetlist(skillsetlist)
    }
  }
  return (<>
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div className="modal-content">
          <div class="modal-header" style={{ backgroundColor: "#fafafa" }}>
            <h6 class="modal-title orangetheme" id="exampleModalLabel">
              Add New Record
            </h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              id="closemodal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-4">


            <div className='my-2'>
              <span className='row'>
                <label className='col-sm-4'>Skillset</label>
                <input className="col-sm-8 form-control" placeholder='Skillset' value={addnewobject.skill} onChange={(e) => setaddnewobject({ ...addnewobject, skill: e.target.value })} />

              </span>
              <span className='row my-2'>
                <label className='col-sm-4'>Skill Type</label>
                <select className="form-select col-sm-8" value={addnewobject.skill_type} onChange={(e) => setaddnewobject({ ...addnewobject, skill_type: e.target.value })}>
                  <option>Select</option>
                  <option>Technical</option>
                  <option>Soft</option>
                </select>
              </span>  </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary btn-sm"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary btn-sm"
              onClick={() => {
                handleadd()


              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="exampleModal2"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModal2Label"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div className="modal-content">
          <div class="modal-header" style={{ backgroundColor: "#fafafa" }}>
            <h6 class="modal-title orangetheme" id="exampleModal2Label">
              Add New Record
            </h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              id="closemodal2"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-4">


            <div className='my-2'>
              <span className='row'>
                <label className='col-sm-4'>Skillset</label>
                <input className="col-sm-8 form-control" value={editobject.skill} placeholder='Skillset' onChange={(e) => seteditobject({ ...editobject, skill: e.target.value })} />

              </span>
              <span className='row my-2'>
                <label className='col-sm-4'>Skill Type</label>
                <select className="form-select col-sm-8" value={editobject.skill_type} onChange={(e) => seteditobject({ ...editobject, skill_type: e.target.value })}>
                  <option>Select</option>
                  <option>Technical</option>
                  <option>Soft</option>
                </select>


              </span>  </div>



          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary btn-sm"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-primary btn-sm"
              onClick={() => {
                handleedit()


              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
    <div>
      {/* <div>
      <img src={bgpage} className='mx-auto'/></div> */}
      <div className='container masterhomepage '  >
        <div className='p-3'>
          <h4 className='my-2 orangetheme'> Skillset </h4>

          <div className='d-flex flex-row-reverse my-3'>

            <i class="fa fa-plus-circle mx-1 text-success" style={{ fontSize: "25px" }} data-toggle={"modal"}
              data-target="#exampleModal"></i>
            <select onChange={(e) => { setskillfilter(e.target.value); counter = 1; }}>
              <option>Select</option>
              <option>Soft</option>
              <option>Technical</option>
            </select>
            <div class="input-group input-group-sm  mx-4">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroup-sizing-sm">Search</span>
              </div>
              <input placeholder='Type atleast 3 characters to search' type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" onChange={(e) => { handlesearch(e.target.value) }} />
            </div>
          </div>
          {filteredskillsetlist?.length > 0 ? <table className='table table-striped mt-4 table-sm'>
            <thead className="" style={{ backgroundColor: "#F79548", color: "white" }}>

              <th>S. No.</th>
              <th>Skillset</th>
              <th>Type</th>
              <th></th>

            </thead>
            <tbody>


              {filteredskillsetlist.filter((ite) => skillfilter === "Select" || ite.skill_type === skillfilter)?.map((item) => {
                return item.skill.split(",")?.map((it) => {
                  return (
                    <tr key={counter}>
                      <td>{counter++}</td> {/* Increment counter here */}
                      <td>{it}</td>
                      <td>{item?.skill_type}</td>
                      <td> <i className='fa fa-edit' onClick={() => { seteditobject(item) }} data-toggle={"modal"}
                        data-target="#exampleModal2"></i></td>

                    </tr>
                  );
                });
              })}
              {/* <tr>
                <td>1</td>
            
                <td>Data Modelling</td>
               
                </tr>
                <tr>
                <td>2</td>
               
                <td>Python</td>
              
               
                </tr>
                <tr>
                <td>3</td>
          <td>ReactJs</td>
                
               
                </tr>
<tr>
    <td>4</td>
    <td>Javascript</td>
</tr>
<tr>
    <td>5</td>
    <td>FastApi</td>
</tr> */}
            </tbody>
          </table> : <div>There are no records to display!!!</div>}



        </div></div></div>
    <FooterPages /> </>
  )
}

export default SkillsetMaster