import React, { useState, useEffect } from 'react'
import FooterPages from './FooterPages';
import UserService from '../services/UserService'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import getNotification from '../Redux/Action/action'
const { REACT_APP_UPLOAD_DATA } = process.env
const { REACT_APP_REDIRECT_URI } = process.env

function VendorMaster() {
  const [searchobject, setsearchobject] = useState("")
  const [addnewscreen, setaddnewscreen] = useState(false);
  const [addnewobject, setaddnewobject] = useState({})
  const [reportingmanagerlist, setreportingmanagerlist] = useState([])
  const dispatch = useDispatch()
  const [spocno, setspocno] = useState([{ no: 0, name: "", phone: 0 }])
  const [filteredvendorlist, setfilteredvendorlist] = useState([])
  const [editobject, seteditobject] = useState({})
  const [vendorlist, setvendorlist] = useState([])
  useEffect(() => {
    handlefetch()
  }, [])
  const handladd = async () => {
    if (UserService.isLoggedIn()) {
      try {
        const FormData = require("form-data");
        const sendData = new FormData();
        sendData.append("jwttoken", UserService.getToken());

        const config = {
          method: "post",
          url: `${REACT_APP_UPLOAD_DATA}/brand/getbrands`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },
          data: sendData,
        };
        const getResponse = await axios(config);
        //console.log(getResponse);
        if (getResponse.data !== "Invalid User!") {
          handlefetch()
          dispatch(
            getNotification({
              message: "Reporting Manager successfully added",
              type: "success",
            })
          );
          // setbrandoptions(getResponse.data);
          // setselectedbrand(getResponse.data[0].brand);
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/vendormaster`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/vendormaster`,
        });
      }, 1000);
    }
  };
  const handlefetch = async () => {
    if (UserService.isLoggedIn()) {
      try {

        const config = {
          method: "get",
          url: `${REACT_APP_UPLOAD_DATA}/vendor`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },

        };
        const getResponse = await axios(config);

        if (getResponse.data !== "Invalid User!") {
          setvendorlist(getResponse.data)
          setfilteredvendorlist(getResponse.data)
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/reporting_managers`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/vendormaster`,
        });
      }, 1000);
    }
  };
  const handlespocadd = () => {
    let arr = [...spocno]
    arr.push(arr[arr.length] + 1)
    setspocno(arr)

  }
  const handlespocremove = (removeindex) => {
    if (removeindex === 0) {

    }
    else {
      let arr = [...spocno]
      arr.pop()
      setspocno(arr)

    }


  }
  const handleeditstatus = async (item) => {
    if (UserService.isLoggedIn()) {
      if (true) {
        try {
          const sendData = {
            rec_id: item.rec_id,
            rec_name: item.rec_name,
            rec_email_id: item.rec_email_id,
            status: item.status === true ? false : true,
            created_by: UserService.getUsername()
          };

          const config = {
            method: "put",
            url: `${REACT_APP_UPLOAD_DATA}/recruitment_manager`,
            headers: {
              Accept: "application/json",  // API expects a JSON response
              "Content-Type": "application/json",  // Send data as JSON
            },
            data: JSON.stringify(sendData), // Convert your object to a JSON string
          };
          const getResponse = await axios(config);
          console.log(getResponse);
          if (getResponse.data !== "Invalid User!") {
            // setbrandoptions(getResponse.data);
            // setselectedbrand(getResponse.data[0].brand);
            handlefetch()
            document.getElementById("closemodal2").click()
            seteditobject({ rec_id: "", rec_email_id: "", rec_name: "", status: "Active" })
            dispatch(
              getNotification({
                message: "Recruitment Manager successfully edited",
                type: "success",
              })
            );
          }
          else {
            UserService.doLogin({
              redirectUri: `${REACT_APP_REDIRECT_URI}/vendormaster`,
            });
          }
        } catch (err) {
          console.log("Server Error", err);
          if (err.response && err.response.status === 500) {
            dispatch(
              getNotification({
                message: "Server is Down! Please try again after sometime",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 400) {
            dispatch(
              getNotification({
                message: "Input is not in prescribed format",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 422) {
            dispatch(
              getNotification({
                message: "Input is not in prescribed format",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 404) {
            dispatch(
              getNotification({
                message: "Page not Found",
                type: "default",
              })
            );
          } else if (err.response && err.response.status === 401) {
            dispatch(
              getNotification({
                message: "Session expired! Please log in again",
                type: "default",
              })
            );
            setTimeout(UserService.doLogin(), 1000)
          } else {
            dispatch(
              getNotification({
                message: "Server is Down! Please try again after sometime",
                type: "default",
              })
            );
          }
        }
      }
      else {
        dispatch(
          getNotification({
            message: "Please fill all entries",
            type: "default",
          })
        );
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/vendormaster`,
        });
      }, 1000);
    }
  }
  const handlesearch = (value) => {
    if (value?.length > 3) {
      setfilteredvendorlist(vendorlist.filter((item) =>
        item.rm_name.toLowerCase().includes(value.toLowerCase())
      ))

    }
    else {
      setfilteredvendorlist(vendorlist)
    }
  }
  return (
    <>
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div class="modal-header" style={{ backgroundColor: "#fafafa" }}>
              <h6 class="modal-title orangetheme" id="exampleModalLabel">
                Add New Record
              </h6>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closemodal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body py-3 px-4">
              <ol type="1">
                <li className='my-2'>
                  <span className='row'>

                    <label className='col-sm-4'>Vendor Id</label>
                    <input className="col-sm-8 form-control" placeholder='Vendor Id' />
                  </span>
                </li>
                <li className='my-2'>
                  <span className='row'>
                    <label className='col-sm-4'>Vendor Name</label>
                    <input className="col-sm-8 form-control" placeholder='Vendor Name' />
                  </span>  </li>

                <li className='my-2'>
                  <span className='row'>
                    <label className='col-sm-4'>Vendor Email</label>
                    <input className="col-sm-8 form-control" placeholder='Vendor Email' />
                  </span>   </li>
                <li className='my-2' id="spoc">

                  <label className=''>Vendor SPOC  </label>
                  <i className='mt-2 fa fa-plus float-end' onClick={() => handlespocadd()} />
                  {spocno?.map((item, index) => {
                    return <div className='row' >

                      <div className='d-flex justify-content-between'>
                        <label>Spoc No.{index + 1}  </label>
                        {spocno?.length === index + 1 && index !== 0 && <i className='my-auto fa fa-minus ' onClick={() => handlespocremove(index)} />}
                      </div>

                      <div className='col-sm d-flex'>
                        <label className='my-auto mx-2'>  Name </label>
                        <input className="form-control" placeholder='SPOC Name' />
                      </div>
                      <div className='col-sm d-flex'>
                        <label className='my-auto mx-2'>Phone </label>
                        <input className="form-control" placeholder='SPOC Phone' />

                      </div>

                    </div>
                  })}
                </li>
                <li className='my-2'>
                  <span className='row'>
                    <label className='col-sm-4'>Vendor MSA document</label>
                    <input className="col-sm-8 form-control" type="file" placeholder='Vendor MSA document upload' />
                  </span>   </li>
              </ol>

            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary btn-sm"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm"
                onClick={() => {
                  if (true) {
                    document.getElementById("scenarionamebox").focus();
                  } else {


                  }
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      <div>
        {/* <div><img src={bgpage} className='mx-auto'/></div> */}

        <div className='container  masterhomepage'  >
          <div className='p-3'>
            <h4 className='my-2 orangetheme'> Vendor Master</h4>
            <div className='d-flex flex-row-reverse my-4'>

              <i class="fa fa-plus-circle mx-1 text-success" style={{ fontSize: "25px" }} data-toggle={"modal"}
                data-target="#exampleModal"></i>
              <div class="input-group input-group-sm  mx-4">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-sizing-sm">Search</span>
                </div>
                <input type="text" placeholder='Type atleast 3 characters to search' class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" onChange={(e) => { handlesearch(e.target.value) }} />
              </div>
            </div>
            {filteredvendorlist?.length > 0 ?
              <table className='table table-striped mt-4 table-sm'>
                <thead>
                  <tr>
                    <th>S. No.</th>
                    <th>Vendor ID</th>
                    <th>Vendor Name</th>
                    <th>Vendor Email</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredvendorlist?.map((item, index) => {
                    return <tr>
                      <td>{index + 1}</td>
                      <td>{item.vendor_id}</td>
                      <td>{item.vendor_name}</td>
                      <td>{item.vendor_email}</td>
                      <td>
                        <div class="form-check form-switch">

                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                            checked={item.status === true}
                            onChange={() => handleeditstatus(item)}
                          />
                          <label class="form-check-label" for="flexSwitchCheckChecked">{item.status === true ? "Active" : "Inactive"}</label>
                        </div>
                      </td>
                      <td> <i className='fa fa-edit' onClick={() => { seteditobject(item) }} data-toggle={"modal"}
                        data-target="#exampleModal2"></i></td>

                    </tr>
                  })}

                </tbody>
              </table> : <div>There are currently no records to display!!!</div>}



          </div></div></div>
      <FooterPages />
    </>
  )
}

export default VendorMaster