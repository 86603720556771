import React,{ useState,useEffect} from 'react'
import bgpage from "./Images/bgpage.jpg"
import FooterPages from './FooterPages'
import UserService from '../services/UserService'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import getNotification from '../Redux/Action/action'
const {REACT_APP_UPLOAD_DATA}=process.env
const {REACT_APP_REDIRECT_URI}=process.env

function DepartmentMaster() {

const [addnewscreen,setaddnewscreen]=useState(false);
const [addnewobject,setaddnewobject]=useState({})
const [statuslist,setstatuslist]=useState([])
let counter=1;
const dispatch=useDispatch()

useEffect(()=>{
  handlefetch()
},[])

const handlefetch = async () => {
  if (UserService.isLoggedIn()) {
    try {
    
      const config = {
        method: "get",
        url: `${REACT_APP_UPLOAD_DATA}/getstatus`,
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
   
      };
      const getResponse = await axios(config);
      console.log(getResponse);
      if (getResponse.data !== "Invalid User!") {
   
      setstatuslist(getResponse.data)
   
        // setbrandoptions(getResponse.data);
        // setselectedbrand(getResponse.data[0].brand);
      }
      else {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/reporting_managers`,
        });
      }
    } catch (err) {
      console.log("Server Error", err);
      if (err.response && err.response.status === 500) {
        dispatch(
          getNotification({
            message: "Server is Down! Please try again after sometime",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 400) {
        dispatch(
          getNotification({
            message: "Input is not in prescribed format",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 422) {
        dispatch(
          getNotification({
            message: "Input is not in prescribed format",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 404) {
        dispatch(
          getNotification({
            message: "Page not Found",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 401) {
        dispatch(
          getNotification({
            message: "Session expired! Please log in again",
            type: "default",
          })
        );
        setTimeout(UserService.doLogin(), 1000)
      } else {
        dispatch(
          getNotification({
            message: "Server is Down! Please try again after sometime",
            type: "default",
          })
        );
      }
    }
  } else {
    setTimeout(() => {
      UserService.doLogin({
        redirectUri: `${REACT_APP_REDIRECT_URI}/DepartmentMaster`,
      });
    }, 1000);
  }
};
const handleadd= async () => {
  if (UserService.isLoggedIn()) {
   if(addnewobject.status){
    try {
      const FormData = require("form-data");
      const sendData = new FormData();
      sendData.append("status_id","");

      sendData.append("department",addnewobject.department);

      sendData.append("created_by",UserService.getUsername());
      const config = {
        method: "post",
        url: `${REACT_APP_UPLOAD_DATA}/add_status`,
        headers: {
          Accept: "text/plain",
          "Content-Type": "application/json",
        },
        data: sendData,
      };
      const getResponse = await axios(config);
      console.log(getResponse);
      if (getResponse.data !== "Invalid User!") {
        // setbrandoptions(getResponse.data);
        // setselectedbrand(getResponse.data[0].brand);
        document.getElementById("closemodal").click()
        setaddnewobject({status:""})
        handlefetch()
        dispatch(
          getNotification({
            message: "status successfully added",
            type: "success",
          })
        );
      }
      else {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/DepartmentMaster`,
        });
      }
    } catch (err) {
      console.log("Server Error", err);
      if (err.response && err.response.status === 500) {
        dispatch(
          getNotification({
            message: "Server is Down! Please try again after sometime",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 400) {
        dispatch(
          getNotification({
            message: "Input is not in prescribed format",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 422) {
        dispatch(
          getNotification({
            message: "Input is not in prescribed format",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 404) {
        dispatch(
          getNotification({
            message: "Page not Found",
            type: "default",
          })
        );
      } else if (err.response && err.response.status === 401) {
        dispatch(
          getNotification({
            message: "Session expired! Please log in again",
            type: "default",
          })
        );
        setTimeout(UserService.doLogin(), 1000)
      } else {
        dispatch(
          getNotification({
            message: "Server is Down! Please try again after sometime",
            type: "default",
          })
        );
      }
    }
   }
   else{
    dispatch(
      getNotification({
        message: "Please fill all entries",
        type: "default",
      })
    );
   }
  } else {
    setTimeout(() => {
      UserService.doLogin({
        redirectUri: `${REACT_APP_REDIRECT_URI}/DepartmentMaster`,
      });
    }, 1000);
  }
};
  return (<>
       <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div className="modal-content">
            <div class="modal-header" style={{ backgroundColor: "#fafafa" }}>
              <h6 class="modal-title orangetheme" id="exampleModalLabel">
              Add New Record
              </h6>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closemodal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body p-4">
    
    
               <div className='my-2'>
          <span className='row'>
          <label className='col-sm-4'>Department</label>
          <input className="col-sm-8 form-control" placeholder='Add department' value={addnewobject.department} onChange={(e)=>setaddnewobject({...addnewobject,department:e.target.value})}/>
          
          </span>  </div>
         
     
     
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary btn-sm"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm"
                onClick={() => {
                 handleadd()

                  
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    <div>
      {/* <div>
      <img src={bgpage} className='mx-auto'/></div> */}
      <div className='container masterhomepage '  >
    <div className='p-3'>
    <h4 className='my-2 orangetheme'> Status Master</h4>
  
    <div className='d-flex flex-row-reverse my-3'>

<i class="fa fa-plus-circle mx-1 text-success" style={{fontSize:"25px"}}  data-toggle={ "modal"}
data-target="#exampleModal"></i>

</div>
   {statuslist?.length>0 ? <table className='table table-striped mt-4 table-sm'>
        <thead>
            <tr>
                <th>S. No.</th>
                
                <th>Department</th>
                
            </tr>
        </thead>
        <tbody>
     

{statuslist?.map((item,index) => {
 
    return (
      <tr >
        <td>{index+1}</td> {/* Increment counter here */}
        <td>{item.department}</td>
      </tr>
    );
  
})}
            {/* <tr>
                <td>1</td>
            
                <td>Data Modelling</td>
               
                </tr>
                <tr>
                <td>2</td>
               
                <td>Python</td>
              
               
                </tr>
                <tr>
                <td>3</td>
          <td>ReactJs</td>
                
               
                </tr>
<tr>
    <td>4</td>
    <td>Javascript</td>
</tr>
<tr>
    <td>5</td>
    <td>FastApi</td>
</tr> */}
        </tbody>
     </table>:<div>There is no record to display!!</div>}

 
    
    </div></div></div>
   <FooterPages/> </>
  )
}

export default DepartmentMaster