import React, { useEffect } from "react";

import { Link, useLocation } from "react-router-dom";
import UserService from "../services/UserService.js";

import quationQ from './Images/quationQ.png'
import getNotification from "../Redux/Action/action.js";
import { useDispatch } from "react-redux";

const { REACT_APP_REDIRECT_URI } = process.env;
const { REACT_APP_UPLOAD_DATA } = process.env;
const { REACT_APP_ADMIN_USER } = process.env
function Navbar() {
  const dispatch = useDispatch();
  const location = useLocation()
  useEffect(()=>{
    // if (UserService.isLoggedIn()) {
    //   dispatch(getNotification({
    //     message: "You are successfully logged in",
    //     type: "success"
    //   }))
    // }
  },[])
  return (
    <>
      {location.pathname !== "/" &&
        <nav className="navbar navbar-expand-sm   navbar-light bg-light" style={{ zIndex: 4 }}>
          <a className="navbar-brand " href="/">
            <img src={quationQ} style={{ height: "32px" }} />
            {" "}
            Recruit Pro
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="">
              <div style={{ width: "30px", height: "3px", backgroundColor: "white", margin: "6px" }}></div>
              <div style={{ width: "30px", height: "3px", backgroundColor: "white", margin: "6px" }}></div>
              <div style={{ width: "30px", height: "3px", backgroundColor: "white", margin: "6px" }}></div>
            </span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li
                className={UserService.isLoggedIn() ? "nav-item-active ml-3" : "d-none"}
              >
                <a className="nav-link" href="/dashboard">
                  <i className='fab fa-accusoft'></i> Dashboard</a>

              </li>
                <li className={UserService.isLoggedIn() && (UserService.getUsername() === REACT_APP_ADMIN_USER) ? "nav-item dropdown" : "d-none"}>
                  <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="fa fa-gear"></i> Master
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a className="dropdown-item" href="/reportingmanagermaster">Reporting Manager </a>
                    <a className="dropdown-item" href="/hiringmanagermaster">Hiring Manager </a>
                    <a className="dropdown-item" href="/employeemaster">Employees </a>
                    <a className="dropdown-item" href="/skillsetmaster"> Skillset </a>
                    <a className="dropdown-item" href="/jobpositionmaster"> Job Position </a>
                    <a className="dropdown-item" href="/recruitmentmanagermaster">Recruiters </a>
                    <a className="dropdown-item" href="/clientmaster">Client </a>
                    <a className="dropdown-item" href="/statusmaster">Status </a>
                    {/* <a class="dropdown-item" href="/departmentmaster">Vertical </a>
                        <a class="dropdown-item" href="/designationmaster">Designation </a>
                        <a class="dropdown-item" href="/rolemaster">Role</a> */}
                    <a className="dropdown-item" href="/vendormaster">Vendor </a>
                  </div>
                </li>
            </ul>
            <ul className="navbar-nav ml-auto">
              <li
                className={
                  UserService.isLoggedIn() ? "nav-item active" : "d-none"
                }
              >
                <Link className="nav-link" to="/">
                  <button className="btn btn-sm  noborder" style={{ color: "black" }} >
                    <i className="fa fa-user" aria-hidden="true"></i> Hi,{" "}
                    {UserService.getFullName()?.split(" ")[0]}
                  </button>
                </Link>
              </li>
              <li
                className={
                  UserService.isLoggedIn() ? "nav-item active" : "d-none"
                }
              >
                <Link className="nav-link" to="/support">
                  <button className="btn btn-sm  noborder" style={{ color: "black" }}>
                    <i className="fa fa-user" aria-hidden="true"></i> Support
                  </button>
                </Link>
              </li>
              <li
                className={
                  UserService.isLoggedIn() ? "d-none" : "nav-item active"
                }
              >
                <Link
                  className="nav-link"
                  onClick={() => {
                    UserService.doLogin({
                      redirectUri: `${REACT_APP_REDIRECT_URI}/dashboard`,
                    })
                  }}
                >
                  <button className="btn btn-sm  noborder" style={{ color: "black" }}>
                    <i className="fas fa-sign-in-alt"></i> Login
                  </button>
                </Link>
              </li>
              <li
                className={
                  UserService.isLoggedIn() ? "d-none" : "nav-item active"
                }
              >
                <Link
                  className="nav-link"
                  onClick={() => UserService.doSignUp()}
                >
                  <button className="btn btn-sm  noborder" style={{ color: "black" }}>
                    <i className="fas fa-user-plus"></i> SignUp
                  </button>
                </Link>
              </li>

              <li
                className={
                  UserService.isLoggedIn() ? "nav-item active" : "d-none"
                }
              >
                <Link
                  className="nav-link"
                  onClick={() => {
                    UserService.doLogout({
                      redirectUri: `${REACT_APP_REDIRECT_URI}`,
                    });
                    // console.log(UserService.getToken());
                  }}
                >
                  <button className="btn btn-sm  noborder" style={{ color: "black" }}>
                    <i className="fas fa-sign-in-alt"></i> Logout
                  </button>
                </Link>
              </li>
            </ul>

          </div>


        </nav>}
    </>
  );
}

export default Navbar;
