import React,{ useState,useEffect} from 'react'
import bgpage from "./Images/bgpage.jpg"
import FooterPages from './FooterPages'
import Select from "react-select";
import UserService from '../services/UserService'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import getNotification from '../Redux/Action/action'
const {REACT_APP_UPLOAD_DATA}=process.env
const {REACT_APP_REDIRECT_URI}=process.env
function JobPositionMaster() {
  const [addnewscreen,setaddnewscreen]=useState(false);
  const [addnewobject,setaddnewobject]=useState({})
  const [reportingmanagerlist,setreportingmanagerlist]=useState([])
  const [jobpositionlist,setjobpositionlist]=useState([])
  const [skillsetlist,setskillsetlist]=useState([])
  const [editobject,seteditobject]=useState({})
  const [searchobject,setsearchobject]=useState("")
const [filteredjobpositionlist,setfilteredjobpositionlist]=useState([])
  const dispatch=useDispatch()
  useEffect(()=>{
    handlefetch()
    handlefetchskills()
  },[])
  const handlefetchskills = async () => {
    if (UserService.isLoggedIn()) {
      try {
      
        const config = {
          method: "get",
          url: `${REACT_APP_UPLOAD_DATA}/skills`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },
     
        };
        const getResponse = await axios(config);
        console.log(getResponse);
        if (getResponse.data !== "Invalid User!") {
     let arr=[]
     {getResponse.data?.map((item)=>{
       item.skill.split(",")?.map((it,index)=>{
        arr.push({label:it,value:it})
       })
    
     })}
    console.log(arr)
        setskillsetlist(arr)
     
          // setbrandoptions(getResponse.data);
          // setselectedbrand(getResponse.data[0].brand);
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/jobpositionmaster`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/jobpositionmaster`,
        });
      }, 1000);
    }
  };
  const handleadd = async () => {
    if (UserService.isLoggedIn()) {
      try {
        const FormData = require("form-data");
        const sendData = new FormData();
        sendData.append("job_title", addnewobject.job_title);
        sendData.append("skillset", addnewobject.skillset.join(","));
        sendData.append("created_by",UserService.getUsername());
        const config = {
          method: "post",
          url: `${REACT_APP_UPLOAD_DATA}/job_position_master`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },
          data: sendData,
        };
        const getResponse = await axios(config);
        //console.log(getResponse);
        if (getResponse.data !== "Invalid User!") {
        handlefetch()
        document.getElementById("closemodal").click()
        setaddnewobject({job_title:"",skillset:[]})
          dispatch(
            getNotification({
              message: "Job Position successfully added",
              type: "success",
            })
          );
          // setbrandoptions(getResponse.data);
          // setselectedbrand(getResponse.data[0].brand);
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/jobpositionmaster`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/jobpositionmaster`,
        });
      }, 1000);
    }
  };
  const handlefetch = async () => {
    if (UserService.isLoggedIn()) {
      try {
      
  
        const config = {
          method: "get",
          url: `${REACT_APP_UPLOAD_DATA}/job_position_master`,
          headers: {
            Accept: "text/plain",
            "Content-Type": "application/json",
          },

        };
        const getResponse = await axios(config);
        //console.log(getResponse);
        if (getResponse.data !== "Invalid User!") {
          // setbrandoptions(getResponse.data);
          // setselectedbrand(getResponse.data[0].brand);
          setjobpositionlist(getResponse.data)
          setfilteredjobpositionlist(getResponse.data)
        }
        else {
          UserService.doLogin({
            redirectUri: `${REACT_APP_REDIRECT_URI}/jobpositionmaster`,
          });
        }
      } catch (err) {
        console.log("Server Error", err);
        if (err.response && err.response.status === 500) {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 400) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 422) {
          dispatch(
            getNotification({
              message: "Input is not in prescribed format",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 404) {
          dispatch(
            getNotification({
              message: "Page not Found",
              type: "default",
            })
          );
        } else if (err.response && err.response.status === 401) {
          dispatch(
            getNotification({
              message: "Session expired! Please log in again",
              type: "default",
            })
          );
          setTimeout(UserService.doLogin(), 1000)
        } else {
          dispatch(
            getNotification({
              message: "Server is Down! Please try again after sometime",
              type: "default",
            })
          );
        }
      }
    } else {
      setTimeout(() => {
        UserService.doLogin({
          redirectUri: `${REACT_APP_REDIRECT_URI}/jobpositionmaster`,
        });
      }, 1000);
    }
  };
  const handlesearch=(value)=>{
    if(value?.length>3){
      setfilteredjobpositionlist( jobpositionlist.filter((item)=>
        item.job_title.toLowerCase().includes(value.toLowerCase())
      ))
     
    }
    else{
      setfilteredjobpositionlist( jobpositionlist)
    }
    }
  const handleedit=async ()=>{
    if (UserService.isLoggedIn()) {
      if(editobject.job_title && editobject.skillset){
       try {
         const sendData = {
           job_title:editobject.job_title,
           skillset:typeof(editobject.skillset)==="string"?editobject.skillset:editobject.skillset.join(","),
           created_on:editobject.created_on.split(".")[0],
           created_by:UserService.getUsername()
         };
         
         const config = {
           method: "put",
           url: `${REACT_APP_UPLOAD_DATA}/job_position_master`,
           headers: {
             Accept: "application/json",  // API expects a JSON response
             "Content-Type": "application/json",  // Send data as JSON

           },
           data: JSON.stringify(sendData), // Convert your object to a JSON string
         };
         const getResponse = await axios(config);
         console.log(getResponse);
         if (getResponse.data !== "Invalid User!") {
           // setbrandoptions(getResponse.data);
           // setselectedbrand(getResponse.data[0].brand);
           handlefetch()
           document.getElementById("closemodal2").click()
           seteditobject({job_title:"",skillset:[]})
           dispatch(
             getNotification({
               message: "Job Position successfully edited",
               type: "success",
             })
           );
         }
         else {
           UserService.doLogin({
             redirectUri: `${REACT_APP_REDIRECT_URI}/jobpositionmaster`,
           });
         }
       } catch (err) {
         console.log("Server Error", err);
         if (err.response && err.response.status === 500) {
           dispatch(
             getNotification({
               message: "Server is Down! Please try again after sometime",
               type: "default",
             })
           );
         } else if (err.response && err.response.status === 400) {
           dispatch(
             getNotification({
               message: "Input is not in prescribed format",
               type: "default",
             })
           );
         } else if (err.response && err.response.status === 422) {
           dispatch(
             getNotification({
               message: "Input is not in prescribed format",
               type: "default",
             })
           );
         } else if (err.response && err.response.status === 404) {
           dispatch(
             getNotification({
               message: "Page not Found",
               type: "default",
             })
           );
         } else if (err.response && err.response.status === 401) {
           dispatch(
             getNotification({
               message: "Session expired! Please log in again",
               type: "default",
             })
           );
           setTimeout(UserService.doLogin(), 1000)
         } else {
           dispatch(
             getNotification({
               message: "Server is Down! Please try again after sometime",
               type: "default",
             })
           );
         }
       }
      }
      else{
       dispatch(
         getNotification({
           message: "Please fill all entries",
           type: "default",
         })
       );
      }
     } else {
       setTimeout(() => {
         UserService.doLogin({
           redirectUri: `${REACT_APP_REDIRECT_URI}/jobpositionmaster`,
         });
       }, 1000);
     }
  }
  return (
    <>
        <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div className="modal-content">
            <div class="modal-header" style={{ backgroundColor: "#fafafa" }}>
              <h6 class="modal-title orangetheme" id="exampleModalLabel">
                Add New Record
              </h6>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closemodal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body p-4">
            <ol type="1">
        <li className='my-2'>
      <span className='row'>

      <label className='col-sm-4'>Job Position</label>
      <input className="col-sm-8 form-control" placeholder='Job Position' value={addnewobject.job_title} onChange={(e)=>setaddnewobject({...addnewobject,job_title:e.target.value})}/>
      </span>
          </li>
               <li className='my-2'>
          <span className='row'>
          <label className='col-sm-3'>Skillset</label>
          {/* <input className="col-sm-8 form-control" placeholder='Skillset'/> */}
          <span className='col-sm-9'>
      
          <Select className=" ml-4 custom-select-width2 " options={skillsetlist}  isSearchable isMulti  onChange={(value)=>{setaddnewobject({...addnewobject,skillset:value?.map((it)=> it.value)})}} value={addnewobject?.skillset?.map((it) => ({ label: it, value: it }))}/></span>
        </span>  </li>
         
      </ol>
     
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary btn-sm"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm"
                onClick={() => {
            handleadd()
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="exampleModal2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModal2Label"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div className="modal-content">
            <div class="modal-header" style={{ backgroundColor: "#fafafa" }}>
              <h6 class="modal-title orangetheme" id="exampleModalLabel">
                Edit Record
              </h6>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closemodal2"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body p-4">
            <ol type="1">
        <li className='my-2'>
      <span className='row'>

      <label className='col-sm-4'>Job Position</label>
      <input className="col-sm-8 form-control" placeholder='Job Position' value={editobject.job_title} onChange={(e)=>seteditobject({...editobject,job_title:e.target.value})}/>
      </span>
          </li>
               <li className='my-2'>
          <span className='row'>
          <label className='col-sm-3'>Skillset</label>
          {/* <input className="col-sm-8 form-control" placeholder='Skillset'/> */}
          <span className='col-sm-9'>
      
          <Select className=" ml-4 custom-select-width2 " options={skillsetlist}  isSearchable isMulti  onChange={(value)=>{console.log(editobject) ;seteditobject({...editobject,skillset:value?.map((it)=> it.value)})}}
value={typeof(editobject?.skillset)==="string"? editobject?.skillset?.includes(",")?editobject?.skillset.split(",").map((it)=>{return { label:it,value:it}}):{label:editobject?.skillset,value:editobject?.skillset}:editobject?.skillset?.map((it)=>{return {label:it,value:it}})}
           /></span>
        </span>  </li>
         
      </ol>
     
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary btn-sm"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm"
                onClick={() => {
            handleedit()
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    <div>
      {/* <div><img src={bgpage} className='mx-auto'/></div> */}
     <div className='container masterhomepage '  >
    <div className='p-3'>
    <h4 className='my-2 orangetheme'>Job Positions </h4>

    <div className='d-flex flex-row-reverse my-3'>

<i class="fa fa-plus-circle mx-1 text-success" style={{fontSize:"25px"}}  data-toggle={ "modal"}
data-target="#exampleModal"></i>
<div class="input-group input-group-sm  mx-4">
  <div class="input-group-prepend">
    <span class="input-group-text" id="inputGroup-sizing-sm">Search</span>
  </div>
  <input placeholder='Type atleast 3 characters to search' type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"  onChange={(e)=>{handlesearch(e.target.value)}} />
</div>
</div>
   {filteredjobpositionlist?.length>0? <table className='table table-striped mt-4 table-sm'>
    <thead className="" style={{backgroundColor:"#F79548",color:"white"}}>
       
                <th>S. No.</th>
                <th>Job Position</th>
                <th>Skillset</th>
                <th></th>
        
        </thead>
        <tbody>
        {filteredjobpositionlist?.map((item,index)=>{
            return <tr>
<td>{index+1}</td>
<td>{item.job_title}</td>
<td>{item.skillset}</td>
<td> <i className='fa fa-edit' onClick={()=>{seteditobject(item)}} data-toggle={ "modal"}
     data-target="#exampleModal2"></i></td>
            </tr>
          })}

        </tbody>
     </table>:"There are currently no records!"}
 
  
    </div></div>
    </div>
    <FooterPages/>
    </>
  )
}

export default JobPositionMaster