import React, { useState } from "react";

const ConsentDiv = ({ onConsent }) => {
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        if (onConsent) {
            onConsent(event.target.checked);
        }
    };

    return (
        <div className="consent-div">
            <div className="form-check">
                <input
                    type="checkbox"
                    id="consentCheckbox"
                    className="form-check-input"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                />
                <label htmlFor="consentCheckbox" className="form-check-label">
                    I agree to the terms and conditions outlined in the Consent for Information Sharing and Application Submission.
                </label>
            </div>
            <p>
                By submitting this application, you confirm that the information provided is accurate and that you consent to the organization processing your data for recruitment purposes in accordance with the{" "}
                <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                </a>.
            </p>
        </div>
    );
};

export default ConsentDiv;
