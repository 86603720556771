import React, { useState,useEffect } from 'react'
import { toast } from 'react-toastify'
import quationQ from '../Images/quationQ.png'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import EmployeeReferalForm from './EmployeeReferalForm'

const EmployeeApplicationForm = () => {
  const navigate = useNavigate()
  const [employeeDetail, setEmployeeDetail] = useState({})
  const [jobDetail, setJobDetail] = useState({})
  const access_token = localStorage.getItem('employee_access_token')
  const job_id = localStorage.getItem('jobId')
  const styles = {
    toggler: {
      borderColor: 'orange',
    },
    togglerIcon: {
      backgroundImage: `url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='orange' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")`,
    },
    logoutButton: {
      backgroundColor: 'orange',  // Orange background
      color: 'white',
      padding: '12px 24px',  // Adjusted padding for a more comfortable button
      fontSize: '16px',
      fontWeight: '600',  // Slightly bolder text
      borderRadius: '5px',
      border: 'none',
      transition: 'all 0.3s ease-in-out',
      display: 'inline-flex',
      alignItems: 'center',
      gap: '8px',  // Space between icon and text
      cursor: 'pointer',
    },
    logoutButtonHover: {
      backgroundColor: '#ff7f2a',  // Lighter, more vibrant orange when hovered
      color: 'white',
      transform: 'scale(1.05)',  // Slight zoom effect for hover
    },
    logoutButtonActive: {
      backgroundColor: '#e67500',  // Even darker orange for active state
      color: 'white',
    },
    welcomeText: {
      fontSize: '18px',
      fontWeight: '600',
      color: '#4a4a4a',
      padding: '10px 20px',
      backgroundColor: '#f5f5f5',
      borderRadius: '5px',
      transition: 'background-color 0.3s ease-in-out',
    },
    welcomeTextHover: {
      backgroundColor: '#e1e1e1',
      cursor: 'pointer',
      cursor: 'select-none'
    },
    logoutIcon: {
      fontSize: '18px',
    },
  };

  // logout Function
  const logout = () => {
    localStorage.removeItem("employee_access_token");
    localStorage.removeItem("jobId");
    navigate('/')
  }
  // Getting Job Detail
  const fetchJobDetail = async () => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UPLOAD_DATA}/job_posting_id?job_id=${job_id}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios(config)
      if (response.status === 200) {
        setJobDetail(response.data.job_posting)
      }
    } catch (error) {
      toast.error('Server is Down.....')
    }
  }
  // Fetching Employee Detail
  const fetchEmployeeDetail = async()=>{
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_UPLOAD_DATA}/authorized/employee`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: { token: access_token },
    };
    try {
      const response = await axios(config)
      if (response.status === 200) {
        setEmployeeDetail(response.data.payload)
      }
    } catch (error) {
      toast.error('Server is Down.....')
    }
  }
  useEffect(() => {
    fetchEmployeeDetail()
    fetchJobDetail()
  }, [])
  return (
    <>
      {/* Navbar */}
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
          <a className="navbar-brand d-flex align-items-center" href="/">
            <div className="logo-container">
              <img src={quationQ} alt="Company Logo" className="logo-img" />
            </div>
            {/* Hide the text (company name) on mobile */}
            <span className="fw-bold text-primary fs-4 ms-3 d-none d-sm-block">Quation Solution Private Limited</span>
          </a>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={styles.toggler}
          >
            <span className="navbar-toggler-icon" style={styles.togglerIcon}></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <span className="nav-link text-dark welcome-text">
                  Welcome, {employeeDetail?.fullName}
                </span>
              </li>
              <li className="nav-item">
                <button
                  className="btn logout-btn"
                  onMouseOver={(e) => {
                    e.target.style.backgroundColor = styles.logoutButtonHover.backgroundColor;
                    e.target.style.color = styles.logoutButtonHover.color;
                  }}
                  onMouseOut={(e) => {
                    e.target.style.backgroundColor = styles.logoutButton.backgroundColor;
                    e.target.style.color = styles.logoutButton.color;
                  }}
                  onClick={() => logout()}
                >
                  <i className="fas fa-sign-out-alt logout-icon"></i> Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      {/* Uneditable Section */}
      <section className="container my-4 bg-light p-4 rounded shadow-sm">
        <h2 className="text-center mb-4 text-primary">Job Details</h2>
        <div className="row text-muted">
          <div className="col-md-6 mb-3">
            <div><strong>Job ID:</strong> {jobDetail?.job_id}</div>
            <div><strong>Job Position:</strong> {jobDetail?.job_position}</div>
          </div>
          <div className="col-md-6 mb-3">
            <div><strong>Salary (in Lakhs):</strong> {jobDetail?.budget_min_range}-{jobDetail?.budget_max_range} p.a.</div>
            <div><strong>Experience:</strong> {jobDetail?.relevant_experience_min}-{jobDetail?.relevant_experience_max} years</div>
          </div>
        </div>
        <div className="row text-muted mt-2">
          <div className="col-md-6">
            <div><strong>Skills:</strong> <span className="text-truncate d-block">{jobDetail?.mandatory_skillset}</span></div>
          </div>
          <div className="col-md-6 mb-3">
            <div><strong>Designation:</strong> {jobDetail?.designation}</div>
            <div><strong>Location:</strong> {jobDetail?.preferred_working_location}</div>
          </div>
        </div>
      </section>

      <EmployeeReferalForm data = {employeeDetail} jobminexpRequired={jobDetail.relevant_experience_min} jobId={jobDetail.job_id}/>
    </>
  )
}

export default EmployeeApplicationForm