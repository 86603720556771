import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from "axios";
import { toast } from 'react-toastify';

const VerifyEmailPage = () => {
    const params = useParams()
    const [isVerified, setVerified] = useState(false)
    const [isChecking, setIsChecking] = useState(false)
    const do_verification = async () => {
        try {
            setIsChecking(true)
            const config = {
                method: "get",
                url: `${process.env.REACT_APP_UPLOAD_DATA}/verifyEmail/${params.token}`,  // Make sure REACT_APP_UPLOAD_DATA is defined in .env
                headers: {
                    Accept: "application/json",  // Expect JSON response
                    "Content-Type": "application/json", // Send data as JSON
                },
            };
            // Send the request and await the response
            const response = await axios(config);
            console.log(response.data.status)
            if (response.data.status) {
                setVerified(true)
                setIsChecking(false)
            }
            else {
                setVerified(false)
                setIsChecking(false)
            }
        } catch (error) {
            if (!error.response.data.status) {
                toast.error(error.response.data.message)
                setIsChecking(false)
            }
        }
    }
    useEffect(() => {
        do_verification()
    }, [])
    return (
        <div className={`d-flex justify-content-center align-items-center vh-100 ${isVerified ? 'bg-success' : isChecking ? 'bg-info' : 'bg-danger'}`}>
            <div className="text-center p-5 rounded shadow-lg animate__animated animate__fadeIn animate__delay-1s" style={{ maxWidth: '600px', width: '100%' }}>
                {/* Add conditional text based on isVerified */}
                {isVerified ? (
                    <p className="fs-3 text-white fw-bold">
                        <i className="bi bi-check-circle-fill me-2"></i> Your Email is Verified
                    </p>
                ) : isChecking ? (
                    <p className="fs-4 text-white">
                        <i className="bi bi-arrow-repeat me-2"></i> Just wait a minute, we are connecting to the server...
                    </p>
                ) : (
                    <p className="fs-4 text-white">
                        <i className="bi bi-arrow-repeat me-2"></i> Verification Link Expires
                    </p>
                )}
            </div>
        </div>

    )
}

export default VerifyEmailPage