import { useState, useEffect } from "react";
import axios from "axios";

const useEmloyeeAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const employee_access_token = localStorage.getItem("employee_access_token");

  useEffect(() => {
    const checkAuth = async () => {
      if (employee_access_token) {
        const config = {
          method: "post",
          url: `${process.env.REACT_APP_UPLOAD_DATA}/employee/protected`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          data: { token: employee_access_token },
        };

        try {
          const response = await axios(config);
          if (response.status === 200) {
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
          }
        } catch (error) {
          console.error("Error during authentication:", error);
          setIsAuthenticated(false);
        }
      } else {
        setIsAuthenticated(false);
      }
      setIsLoading(false); // Mark loading as complete
    };

    checkAuth();
  }, [employee_access_token]);

  return { isAuthenticated, isLoading }; // Return both values
};

export default useEmloyeeAuth;