import React,{useEffect,useState,useRef} from "react";
import UserService from "../services/UserService";
import getNotification from "../Redux/Action/action";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import quationlogoBg0 from "./Images/quationlogoBg0.png";

import logosocialmedia from "./Images/logossocialmodeia.png";


import './home.css';
import Navbar2 from "./Navbar2";
import { useSpring, animated,useScroll,useTrail,useTransition,useInView } from '@react-spring/web';

function Home() {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const scrollToSection = (sectionname) => {
    // Replace 'section2' with the id of the section you want to scroll to
      document
        .getElementById(sectionname)
        .scrollIntoView({ behavior: "smooth" });
    
  };
  const isElementInViewport = (el) => {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  const onScroll = () => {
    if (elementRef.current && isElementInViewport(elementRef.current)) {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    onScroll(); // Check if the element is already in the viewport

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

 const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <>
   
<Navbar2  scrollToSection={scrollToSection}/>

<div className="home px-5 d-flex align-items-center" >
 <h3 className="text-light" style={{color:""}}>Recruitment Tool</h3>
</div>
      <div className="text-center p-1" style={{ backgroundColor: "#fafafa" }}>
        {" "}
        <img
          className=""
          style={{ width: "160x", height: "35px" }}
          src={quationlogoBg0}
        />
      </div>
      <div className=" text-center text-secondary text-sm" style={{ backgroundColor: "#fafafa",color:"black" }}>
      Copyright©2023,Quation Solutions Private Limited.All rights reserved.
      </div>
    </>
  );
}

export default Home;
